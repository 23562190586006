import { i18nKeys } from '@/locales/keys'
import { Trans, useTranslation } from 'react-i18next'

import { H4, LI, P, UL } from '@/components/typography'

const HabitatSensitivity = () => {
  const { t } = useTranslation()
  return (
    <div className="py-4">
      <H4>{t(i18nKeys.beeoimpact.methodology.sensitivity.title)}</H4>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.sensitivity.description}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>

      <H4>{t(i18nKeys.beeoimpact.methodology.sensitivity.code.title)}</H4>

      <UL>
        <LI>
          <Trans
            i18nKey={i18nKeys.beeoimpact.methodology.sensitivity.code.scale.li1}
            components={{ bold: <span className="font-bold" /> }}
          />
        </LI>
        <LI>
          <Trans
            i18nKey={i18nKeys.beeoimpact.methodology.sensitivity.code.scale.li2}
            components={{ bold: <span className="font-bold" /> }}
          />
        </LI>
        <LI>
          <Trans
            i18nKey={i18nKeys.beeoimpact.methodology.sensitivity.code.scale.li3}
            components={{ bold: <span className="font-bold" /> }}
          />
        </LI>
        <LI>
          <Trans
            i18nKey={i18nKeys.beeoimpact.methodology.sensitivity.code.scale.li4}
            components={{ bold: <span className="font-bold" /> }}
          />
        </LI>
        <LI>
          <Trans
            i18nKey={i18nKeys.beeoimpact.methodology.sensitivity.code.scale.li5}
            components={{ bold: <span className="font-bold" /> }}
          />
        </LI>
      </UL>
    </div>
  )
}

export default HabitatSensitivity
