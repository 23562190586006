export const pressureValue = {
  VH: 7,
  H: 6,
  M: 5,
  L: 4,
  VL: 3,
  ND: 2,
  N: 1,
}

export const pressureValueToLabel = {
  7: 'VH',
  6: 'H',
  5: 'M',
  4: 'L',
  3: 'VL',
  2: 'ND',
  1: 'N',
}
