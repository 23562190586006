import React, { useState } from 'react'
import { getInitiativesListQueryKey, useInitiativesCreate } from '@/client/backend/api/initiatives/initiatives'
import { Initiative } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetHeader, SheetPortal, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Textarea } from '@/components/ui/textarea'
import { Icons } from '@/components/icons'
import { H3 } from '@/components/typography'

type FormCreateInitiativeProps = {
  onInitiativeSave: (newInitiative: Initiative) => void
  children: React.ReactNode
}

const costText = (value, t) => {
  const costMap = {
    1: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.lowCost1),
    2: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.lowCost2),
    3: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.moderateCost),
    4: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.highCost1),
    5: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.highCost2),
  }
  return costMap[value] || ''
}

const FormCreateInitiative = ({ children, onInitiativeSave }: FormCreateInitiativeProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)

  const handleOpenChange = (newOpenStatus) => {
    if (!newOpenStatus) {
      setCurrentStep(1)
    }
    setIsOpen(newOpenStatus)
  }

  const stepTitleMap = {
    1: 1,
    2: 1,
    3: 2,
    4: 3,
  }

  return (
    <Sheet open={isOpen} onOpenChange={handleOpenChange}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetPortal>
        <SheetContent className="w-[700px] max-w-[700px] overflow-auto sm:w-[540px]" side="right">
          <SheetHeader className="pb-4">
            <SheetTitle>Créer mon initiative</SheetTitle>
          </SheetHeader>
          <Separator className="mb-6" />
          <CountStep currentStep={stepTitleMap[currentStep]} />
          <div className="h-7" />
          <InitiativeForm
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onInitiativeSave={onInitiativeSave}
            setIsOpen={setIsOpen}
          />
        </SheetContent>
      </SheetPortal>
    </Sheet>
  )
}

export default FormCreateInitiative

const CountStep = ({ currentStep }: { currentStep: number }) => {
  const Bubble = ({ children, isActive = false, validated = false, filled = false }) => (
    <div
      className={`flex size-7 items-center justify-center rounded-full border-2 ${
        isActive ? 'border-biodivDarkGreyTeal' : 'border-biodivDarkGrey'
      } ${isActive ? 'text-biodivDarkGreyTeal' : 'text-biodivDarkGrey'} ${filled ? 'bg-biodivDarkGrey' : 'bg-none'}`}
    >
      <div className={`${filled ? 'text-white' : 'text-nowrap'}`}>{validated ? <Icons.Check /> : children}</div>
    </div>
  )

  const DashedLine = () => <div className="mx-1 h-1 flex-1 border-t-2 border-dashed border-biodivDarkGrey" />

  const CountLabel = ({ children, isActive = false }) => (
    <div className="flex size-7 items-center justify-center">
      <div className={`${isActive ? 'text-biodivDarkGreyTeal' : 'text-biodivDarkGrey'}`}>{children}</div>
    </div>
  )

  return (
    <>
      <div className="flex items-center justify-between px-5">
        <Bubble isActive={currentStep === 1} validated={currentStep > 1}>
          1
        </Bubble>
        <DashedLine />
        <Bubble isActive={currentStep === 2} filled={currentStep === 2} validated={currentStep > 2}>
          2
        </Bubble>
        <DashedLine />
        <Bubble isActive={currentStep === 3} filled={currentStep === 3}>
          3
        </Bubble>
      </div>
      <div className="mt-4 flex items-center justify-between px-5">
        <CountLabel isActive={currentStep === 1}>Votre initiative</CountLabel>
        <div className="flex-1" />
        <CountLabel isActive={currentStep === 2}>Caractéristiques</CountLabel>
        <div className="flex-1" />
        <CountLabel isActive={currentStep === 3}>Valider</CountLabel>
      </div>
    </>
  )
}

const FormSchema = z.object({
  initiative_name: z.string().min(2, 'Initiative name must be at least 2 characters.'),
  picture: z.any().optional(),
  description: z.string().min(2, 'Description must be at least 2 characters.'),
  cost: z.number().min(1, 'Cost must be at least 1.'),
  step: z.string().min(2, 'Step must be at least 2 characters.'),
})

type InitiativeFormProps = {
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  onInitiativeSave: (newInitiative: Initiative) => void
  setIsOpen: (value: boolean) => void
}

const InitiativeForm = ({ currentStep, setCurrentStep, onInitiativeSave, setIsOpen }: InitiativeFormProps) => {
  const { t } = useTranslation()
  const mutation = useInitiativesCreate()
  const queryClient = useQueryClient()

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      initiative_name: '',
      picture: undefined,
      description: '',
      cost: 0,
      step: '',
    },
  })

  type FormFields = 'cost' | 'initiative_name' | 'picture' | 'description' | 'step'

  const handleNextStep = async (fieldNames: FormFields[]) => {
    const isValid = await form.trigger(fieldNames)
    if (isValid) {
      setCurrentStep((prev) => prev + 1)
    }
  }

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const onSubmit = async (data) => {
    let imageBase64: string | undefined = undefined
    if (data.picture?.[0]) {
      imageBase64 = await convertToBase64(data.picture[0])
    }
    mutation.mutate(
      {
        data: {
          cost: data.cost,
          definition: data.description,
          full_name: data.initiative_name,
          how: data.step,
          short_name: data.initiative_name,
          image: imageBase64,
        },
      },
      {
        onSuccess: (e) => {
          const initiative = e as unknown as Initiative
          setIsOpen(false)
          setCurrentStep(1)
          onInitiativeSave(initiative)
          queryClient.invalidateQueries({ queryKey: [getInitiativesListQueryKey()] })
        },
      }
    )
  }

  const renderFormField = (name, label, placeholder, Component, additionalProps = {}) => (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <FormControl>
            <Component placeholder={placeholder} {...field} {...additionalProps} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex min-h-[calc(100vh-240px)] flex-col space-y-6">
        {(currentStep === 1 || currentStep === 2) && (
          <>
            <H3>Votre initiative</H3>
            <p>Choisissez un nom pour votre initiative et vérifier qu’elle n’existe pas encore sur notre plateforme.</p>
            {renderFormField('initiative_name', '', 'Nom de l’initiative', Input)}
            {currentStep === 1 && (
              <Button type="button" onClick={() => handleNextStep(['initiative_name'])} className="mt-auto w-full">
                Continuer
              </Button>
            )}
          </>
        )}

        {currentStep === 2 && (
          <>
            <FormField
              control={form.control}
              name="picture"
              render={() => (
                <FormItem>
                  <FormLabel>Image de présentation</FormLabel>
                  <FormControl>
                    <input type="file" accept="image/*, application/pdf" {...form.register('picture')} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {renderFormField('description', 'Description courte', 'Description courte...', Textarea)}
            <Button type="button" onClick={() => handleNextStep(['picture', 'description'])} className="mt-auto w-full">
              Continuer
            </Button>
          </>
        )}

        {currentStep === 3 && (
          <>
            <H3>Caractéristiques</H3>
            <p>Complétez les informations de votre initiative afin de nous aider à la valider.</p>
            <Separator />
            <FormField
              control={form.control}
              name="cost"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Coût</FormLabel>
                  <FormControl>
                    <Rating onChangeValue={(value) => form.setValue('cost', value)} value={field.value} />
                  </FormControl>
                  <FormLabel>{costText(field.value, t)}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator />
            {renderFormField(
              'step',
              'Mise en place de votre initiative : étape par étape',
              '## 1 The first step...',
              Textarea
            )}
            <Button type="button" onClick={() => handleNextStep(['cost', 'step'])} className="mt-auto w-full">
              Continuer
            </Button>
          </>
        )}

        {currentStep === 4 && (
          <>
            <H3>Valider</H3>
            <p>Après cette étape vous pourrez retrouver votre initiative et la planifier sur vos sites.</p>
            <strong>Soumettre votre initiative ?</strong>
            <p>
              Soumettre votre initiative ?Si vous le souhaitez vous pourrez soumettre votre initiative à notre équipe
              qui se chargera de la compléter et de la rendre disponible à tous.
            </p>
            <Button type="submit" disabled={mutation.isPending} className="w-full border-black font-semibold">
              {mutation.isPending && <Icons.Loader2 className="mr-2 size-4 animate-spin" />}
              Valider
            </Button>
          </>
        )}
      </form>
    </Form>
  )
}

const Rating = ({ onChangeValue, value }) => {
  const [rating, setRating] = useState(value || 0)

  const handleRatingChange = (newRating: number) => {
    setRating(newRating)
    onChangeValue(newRating)
  }

  return (
    <div className="flex cursor-pointer items-center gap-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <Icons.Euro
          key={star}
          className={`size-6 ${star <= rating ? 'stroke-biodivDarkGreyTeal' : 'stroke-biodivLightGrey'}`}
          onClick={() => handleRatingChange(star)}
        />
      ))}
    </div>
  )
}
