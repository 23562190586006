import { accountsSitesList, getAccountsSitesListQueryKey } from '@/client/backend/api/accounts/accounts'
import { getUsersMeRetrieveQueryKey, usersMeRetrieve } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import SettingsSitesTable from '@/components/settings-sites-table'

const SitesSettings = () => {
  const { t } = useTranslation()

  const { data: currentUser } = useQuery({
    queryKey: [getUsersMeRetrieveQueryKey()],
    queryFn: () => usersMeRetrieve(),
  })

  const { data: sites } = useQuery({
    queryKey: currentUser?.account_id ? [getAccountsSitesListQueryKey(currentUser.account_id)] : [],
    queryFn: () => accountsSitesList(currentUser!.account_id),
    enabled: !!currentUser?.id,
  })

  if (!sites) {
    return <Skeleton className="block h-96" />
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.sites.title)}</h3>
        <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.sites.description)}</p>
      </div>
      <Separator />
      <SettingsSitesTable data={sites.features ?? []} />
    </div>
  )
}

export default SitesSettings
