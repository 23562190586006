import { useEffect, useRef, useState } from 'react'
import { i18nKeys } from '@/locales/keys'
import { LatLngBounds } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { ImageOverlay, Tooltip, useMapEvents } from 'react-leaflet'

type Color = {
  r: number
  g: number
  b: number
}

type ColorLegend = {
  color: Color
  labelKey: string
}

type ColorLegendOverlayProps = {
  url: string
  bounds: LatLngBounds
  opacity: number
  legends: ColorLegend[]
}

const isSameColor = ({ r, g, b }: Color, [r2, g2, b2]: Uint8ClampedArray, tolerance = 5) => {
  return Math.abs(r - r2) <= tolerance && Math.abs(g - g2) <= tolerance && Math.abs(b - b2) <= tolerance
}
const retrieveLegendByPixelColor = (pixelData: Uint8ClampedArray, legends: ColorLegend[]) =>
  legends.find(({ color }) => isSameColor(color, pixelData))

/**
 * InteractiveColorLegendOverlay component.
 *
 * Displays an image overlay with color legends that show a tooltip when hovering over the image.
 * The legends are defined by the color of the pixels in the image.
 */
const InteractiveColorLegendOverlay = ({ url, bounds, opacity, legends }: ColorLegendOverlayProps) => {
  const { t } = useTranslation()
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const [legend, setLegend] = useState<string | null>(null)

  // Load the image into a canvas for pixel reading
  useEffect(() => {
    const canvas = document.createElement('canvas')
    const image = new Image()

    image.crossOrigin = 'anonymous' // To avoid CORS issues
    image.src = url

    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext('2d')?.drawImage(image, 0, 0)
      canvasRef.current = canvas
      imageRef.current = image
    }

    return () => {
      canvasRef.current = null
      imageRef.current = null
    }
  }, [url])

  // Handle mouse movement to detect pixel color
  useMapEvents({
    mousemove(e) {
      if (!bounds.contains(e.latlng) || !imageRef.current || !canvasRef.current) {
        setLegend(null)
        return
      }

      const { lat, lng } = e.latlng
      const overlayTopLeft = bounds.getNorthWest()
      const overlayBottomRight = bounds.getSouthEast()
      const imageWidth = imageRef.current.width
      const imageHeight = imageRef.current.height

      const x = Math.floor(((lng - overlayTopLeft.lng) / (overlayBottomRight.lng - overlayTopLeft.lng)) * imageWidth)
      const y = Math.floor(((overlayTopLeft.lat - lat) / (overlayTopLeft.lat - overlayBottomRight.lat)) * imageHeight)

      const ctx = canvasRef.current.getContext('2d')
      const pixelData = ctx?.getImageData(x, y, 1, 1).data

      if (pixelData) {
        const legend = retrieveLegendByPixelColor(pixelData, legends)
        setLegend(legend?.labelKey ?? i18nKeys.beeoimpact.biodiversityTable.spectralIndices.legendNotAvailable)
      }
    },
  })

  return (
    <>
      <ImageOverlay url={url} bounds={bounds} opacity={opacity} interactive>
        {legend && <Tooltip sticky>{t(legend)}</Tooltip>}
      </ImageOverlay>
    </>
  )
}

export default InteractiveColorLegendOverlay
