import { User } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ArrowDownWideNarrow, ArrowUpWideNarrow } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { SortOption } from '@/types/sort-options'
import { SortType } from '@/types/sort-type'
import { AVAILABLE_FILTERS } from '@/lib/available-filters'
import { hasAccesTo } from '@/lib/permission'
import { Separator } from '@/components/ui/separator'
import SitesFilterScoring from '@/components/sites/sites-filter-scoring'
import SitesFilterSearch from '@/components/sites/sites-filter-search'

interface SitesFiltersProps {
  user: User
  availableFilters?: AVAILABLE_FILTERS[]
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSortByPesticidesScore?: (sortType: SortType) => void
  onSortByBiodiversityScore?: (sortType: SortType) => void
}

export const SitesFilters = ({
  user,
  availableFilters,
  onInputChange,
  onSortByPesticidesScore,
  onSortByBiodiversityScore,
}: SitesFiltersProps) => {
  const { t } = useTranslation()

  const SORT_CONFIG: Record<SortType, SortOption> = {
    [SortType.ASC]: {
      label: t(i18nKeys.beeoimpact.sites.risk.low),
      icon: <ArrowUpWideNarrow className="mr-2 size-3.5 text-muted-foreground/70" />,
    },
    [SortType.DESC]: {
      label: t(i18nKeys.beeoimpact.sites.risk.high),
      icon: <ArrowDownWideNarrow className="mr-2 size-3.5 text-muted-foreground/70" />,
    },
  }

  return (
    <div className="flex flex-1 items-center space-x-2 pl-2">
      <SitesFilterSearch onInputChange={onInputChange} />

      <Separator orientation="vertical" />

      {hasAccesTo(user.features, FeatureType.PESTICIDES) &&
        availableFilters?.includes(AVAILABLE_FILTERS.SCORE_PESTICIDES) && (
          <>
            <SitesFilterScoring
              onSortChange={onSortByPesticidesScore ?? (() => {})}
              placeholder={t(i18nKeys.beeoimpact.sites.top.sortByPesticide)}
              sortConfig={SORT_CONFIG}
            />
            <Separator orientation="vertical" />
          </>
        )}

      {hasAccesTo(user.features, FeatureType.BIODIVERSITY) &&
        availableFilters?.includes(AVAILABLE_FILTERS.SCORE_BIODIVERSITY) && (
          <SitesFilterScoring
            onSortChange={onSortByBiodiversityScore ?? (() => {})}
            placeholder={t(i18nKeys.beeoimpact.sites.top.sortByBiodiv)}
            sortConfig={SORT_CONFIG}
          />
        )}
    </div>
  )
}
