import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { NaceCode, NaceCodesListParams } from '../../models'

export const naceCodesList = (params?: NaceCodesListParams, signal?: AbortSignal) => {
  return backendInstance<NaceCode[]>({ url: `/nace-codes/`, method: 'GET', params, signal })
}

export const getNaceCodesListQueryKey = (params?: NaceCodesListParams) => {
  return [`/nace-codes/`, ...(params ? [params] : [])] as const
}

export const getNaceCodesListQueryOptions = <
  TData = Awaited<ReturnType<typeof naceCodesList>>,
  TError = ErrorType<unknown>,
>(
  params?: NaceCodesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof naceCodesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNaceCodesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof naceCodesList>>> = ({ signal }) =>
    naceCodesList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof naceCodesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type NaceCodesListQueryResult = NonNullable<Awaited<ReturnType<typeof naceCodesList>>>
export type NaceCodesListQueryError = ErrorType<unknown>

export const useNaceCodesList = <TData = Awaited<ReturnType<typeof naceCodesList>>, TError = ErrorType<unknown>>(
  params?: NaceCodesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof naceCodesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNaceCodesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
