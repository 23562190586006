import {
  getSitesClcListQueryKey,
  getSitesHabitatsListQueryKey,
  getSitesNaceCodePressuresListQueryKey,
  getSitesRetrieveQueryKey,
  getSitesSpeciesListQueryKey,
  getSitesSpectralIndicesListQueryKey,
  sitesClcList,
  sitesHabitatsList,
  sitesNaceCodePressuresList,
  sitesRetrieve,
  sitesSpeciesList,
  sitesSpectralIndicesList,
} from '@/client/backend/api/sites/sites'
import { CLCPlot } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { getGbifMarkers } from '@/lib/gbif'
import { getSiteCenter } from '@/lib/geo'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import HabitatHeatmap from '@/components/beeoimpact/habitat-heatmap'
import HeatmapSkeleton from '@/components/beeoimpact/habitat-heatmap-skeleton'
import NaceCodeHeatmap from '@/components/beeoimpact/nace-code-heatmap'
import CallToACtion from '@/components/beeoimpact/sites/cta'
import ClcTable from '@/components/beeoimpact/tables/clc-table'
import SpeciesGbifTable from '@/components/beeoimpact/tables/species-gbif-table'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import CardSpectral from '@/components/card-spectral'
import DataTableSkeleton from '@/components/data-table/data-table-skeleton'
import ClcGbifMap from '@/components/map'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

export interface GbifMarker {
  id: number
  name: string
  marker: LatLng
  category: string
  clc: CLCPlot[]
}

const GeneralAnalysisPage = () => {
  const { t } = useTranslation()

  const { siteId } = useParams<{ siteId: string }>()
  if (!siteId) throw new Error('siteId is required')

  const { data: clc, isLoading: isLoadingClc } = useQuery({
    queryKey: [getSitesClcListQueryKey(siteId)],
    queryFn: () => sitesClcList(siteId),
  })

  const { data: gbif, isLoading: isLoadingGbif } = useQuery({
    queryKey: [getSitesSpeciesListQueryKey(siteId)],
    queryFn: () => sitesSpeciesList(siteId),
  })

  const { data: site } = useQuery({
    queryKey: [getSitesRetrieveQueryKey(siteId)],
    queryFn: () => sitesRetrieve(siteId),
  })

  const { data: habitats, isLoading: isLoadingHabitats } = useQuery({
    queryKey: [getSitesHabitatsListQueryKey(siteId)],
    queryFn: () => sitesHabitatsList(siteId, undefined),
  })

  const { data: spectralIndices } = useQuery({
    queryKey: [getSitesSpectralIndicesListQueryKey(siteId)],
    queryFn: () => sitesSpectralIndicesList(siteId, undefined),
  })

  const { data: naceCodes } = useQuery({
    queryKey: [getSitesNaceCodePressuresListQueryKey(siteId)],
    queryFn: () => sitesNaceCodePressuresList(siteId),
  })

  const center = site ? getSiteCenter(site) : null

  const getMarkers = getGbifMarkers(gbif ?? [])

  return (
    <div className="container relative my-8">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoimpact),
          },
          {
            label: t(i18nKeys.beeoimpact.common.breadcrumb.sites),
            href: getPath(Routes.BEEOIMPACT_SITES),
          },
          {
            label: site?.properties?.name ?? t(i18nKeys.beeoimpact.common.breadcrumb.site),
            href: getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site?.id as number } }),
          },
        ]}
        currentPage={t(i18nKeys.beeoimpact.common.breadcrumb.potential)}
      />
      <div className="mb-4 flex flex-col justify-between">
        <PageHeader>
          <PageHeaderHeading>{t(i18nKeys.beeoimpact.common.breadcrumb.potential)}</PageHeaderHeading>
        </PageHeader>
        <div className="mb-4">
          <CallToACtion
            href={`mailto:info@beeodiversity.com?Subject=${encodeURIComponent(t(i18nKeys.beeoimpact.site.cta.subject, { siteName: site?.properties?.name }))}`}
          />
        </div>
      </div>
      <div className="mb-4 flex flex-col justify-center">
        <div className=" h-[30rem] w-full rounded-md">
          {isLoadingClc && isLoadingGbif ? (
            <div className="flex size-full items-center justify-center">
              <Skeleton className="size-full h-[30rem]" />
            </div>
          ) : (
            center && <ClcGbifMap center={center} gbifMarkers={getMarkers} clc={clc ?? []} />
          )}
        </div>

        <div>
          <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.title)}</H3>
          <p className="text-sm text-muted-foreground">
            {t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.description)}
            <p>
              <Link
                to={`${getPath(Routes.BEEOIMPACT_METHODOLOGY, {
                  section: 'spectral-indices',
                })}`}
                className="underline"
              >
                {t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.link)}
              </Link>
            </p>
          </p>
          <Separator />
        </div>
        <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="col-span-12">
            {spectralIndices && center && <CardSpectral center={center} data={spectralIndices} />}
          </div>
        </div>
        <div>
          <div>
            <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.species_gbif)}</H3>
            <Separator />
          </div>
          <div className="mt-8">
            {isLoadingGbif ? (
              <DataTableSkeleton rows={5} columns={6} />
            ) : (
              site && <SpeciesGbifTable data={gbif ?? []} site={site} />
            )}
          </div>
        </div>

        <div>
          <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.clc)}</H3>
          <Separator />
        </div>
        <div className="mt-8">
          {isLoadingClc ? <DataTableSkeleton rows={5} columns={5} /> : site && <ClcTable clc={clc} />}
        </div>
      </div>
      {habitats && habitats.length > 0 && (
        <>
          <div>
            <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.habitat.title)}</H3>
            <Separator />
          </div>
          <div className="mt-8">
            {isLoadingHabitats ? <HeatmapSkeleton /> : <HabitatHeatmap habitats={habitats ?? []} />}
          </div>
        </>
      )}
      {naceCodes && naceCodes.length > 0 && (
        <>
          <div>
            <H3 className="mb-4">{t(i18nKeys.beeoimpact.biodiversityTable.nacecode.title)}</H3>
            <Separator />
          </div>
          <div className="mt-8">
            {isLoadingHabitats ? <HeatmapSkeleton /> : <NaceCodeHeatmap naceCodes={naceCodes ?? []} />}
          </div>
        </>
      )}
    </div>
  )
}

export default GeneralAnalysisPage
