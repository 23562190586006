import { accountsList, getAccountsListQueryKey } from '@/client/backend/api/accounts/accounts'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import AccountsTable from '@/components/accounts-table'
import { H3, P } from '@/components/typography'

const AccountsPage = () => {
  const { t } = useTranslation()

  const { data: accounts, isLoading } = useQuery({
    queryKey: [getAccountsListQueryKey()],
    queryFn: () => accountsList(),
  })

  if (isLoading || !accounts) {
    return <Skeleton className="block h-[500px]" />
  }

  return (
    <div className="space-y-6">
      <H3 className="text-lg font-medium">{t(i18nKeys.global.admin.page.accounts.title)}</H3>
      <P className="text-sm text-muted-foreground">{t(i18nKeys.global.admin.page.accounts.description)}</P>
      <Separator />

      <AccountsTable accounts={accounts} />
    </div>
  )
}

export default AccountsPage
