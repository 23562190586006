import { i18nKeys } from '@/locales/keys'
import { Trans, useTranslation } from 'react-i18next'

import { H4, H5, LI, P, UL } from '@/components/typography'

const SpectralIndices = () => {
  const { t } = useTranslation()
  return (
    <div className="scroll-mt-20 py-4" id="spectral-indices">
      <H4>{t(i18nKeys.beeoimpact.methodology.spectralIndices.title)}</H4>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.spectralIndices.description}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
      <H5>{t(i18nKeys.beeoimpact.methodology.spectralIndices.NDVI.title)}</H5>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.spectralIndices.NDVI.definition.p1}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.spectralIndices.NDVI.definition.p2}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.spectralIndices.NDVI.definition.p3}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
      <H5>{t(i18nKeys.beeoimpact.methodology.spectralIndices.NDWI.title)}</H5>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.spectralIndices.NDWI.definition.p1}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.spectralIndices.NDWI.definition.p2}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
      <H5>{t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.title)}</H5>
      {/* Example 1 */}
      <div>
        <P className="italic underline">{t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.title)}</P>
        <UL>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.context.title)}
            </span>
            : {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.context.description)}
          </LI>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.dataObserved.title)}
            </span>
            :
            <UL>
              <LI>
                {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.dataObserved.description.p1)}
              </LI>
              <LI>
                {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.dataObserved.description.p2)}
              </LI>
            </UL>
          </LI>

          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.title)}
            </span>
            :
            <UL>
              <LI>
                <Trans
                  i18nKey={
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.description.p1
                  }
                  components={{ bold: <span className="font-bold" /> }}
                />
              </LI>
              <LI>
                <Trans
                  i18nKey={
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.description.p2
                  }
                  components={{ bold: <span className="font-bold" /> }}
                />
              </LI>
            </UL>
          </LI>
        </UL>
        <P>{t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.conclusion)}</P>
        <UL>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.action.title)}
            </span>
            : {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example1.interpretation.action.description)}
          </LI>
        </UL>
      </div>
      {/* Example 2 */}
      <div>
        <P className="italic underline">{t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.title)}</P>
        <UL>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.context.title)}
            </span>
            : {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.context.description)}
          </LI>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.dataObserved.title)}
            </span>
            :
            <UL>
              <LI>
                {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.dataObserved.description.p1)}
              </LI>
              <LI>
                {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.dataObserved.description.p2)}
              </LI>
            </UL>
          </LI>

          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.title)}
            </span>
            :
            <UL>
              <LI>
                <Trans
                  i18nKey={
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.description.p1
                  }
                  components={{ bold: <span className="font-bold" /> }}
                />
              </LI>
              <LI>
                <Trans
                  i18nKey={
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.description.p2
                  }
                  components={{ bold: <span className="font-bold" /> }}
                />
              </LI>
            </UL>
          </LI>
        </UL>
        <P>{t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.conclusion)}</P>
        <UL>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.action.title)}
            </span>
            : {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.action.description)}
          </LI>
        </UL>
      </div>
      {/* Example 3 */}
      <div>
        <P className="italic underline">{t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.title)}</P>
        <UL>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.context.title)}
            </span>
            : {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.context.description)}
          </LI>
          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.title)}
            </span>
            :
            <UL>
              <LI>
                <span className="font-bold">
                  {t(
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.spring
                      .title
                  )}
                </span>
                :{' '}
                <UL>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.spring
                        .p1
                    )}
                  </LI>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.spring
                        .p2
                    )}
                  </LI>
                </UL>
              </LI>
              <LI>
                <span className="font-bold">
                  {t(
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.summer
                      .title
                  )}
                </span>
                :{' '}
                <UL>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.summer
                        .p1
                    )}
                  </LI>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.dataObserved.description.summer
                        .p2
                    )}
                  </LI>
                </UL>
              </LI>
            </UL>
          </LI>

          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.title)}
            </span>
            <UL>
              <LI>
                <span className="font-bold">
                  {t(
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.spring
                      .title
                  )}
                </span>
                :{' '}
                <UL>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                        .spring.p1.title
                    )}
                    <P>
                      {t(
                        i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                          .spring.p1.description
                      )}
                    </P>
                  </LI>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                        .spring.p2.title
                    )}
                    <P>
                      {t(
                        i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                          .spring.p2.description
                      )}
                    </P>
                  </LI>
                </UL>
              </LI>
              <LI>
                <span className="font-bold">
                  {t(
                    i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description.summer
                      .title
                  )}
                </span>
                :{' '}
                <UL>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                        .summer.p1.title
                    )}
                    <P>
                      {t(
                        i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                          .summer.p1.description
                      )}
                    </P>
                  </LI>
                  <LI>
                    {t(
                      i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                        .summer.p2.title
                    )}
                    <P>
                      {t(
                        i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example3.interpretation.description
                          .summer.p2.description
                      )}
                    </P>
                  </LI>
                </UL>
              </LI>
            </UL>
          </LI>

          <LI>
            <span className="font-bold">
              {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.action.title)}
            </span>
            : {t(i18nKeys.beeoimpact.methodology.spectralIndices.analysis.example2.interpretation.action.description)}
          </LI>
        </UL>
      </div>
    </div>
  )
}

export default SpectralIndices
