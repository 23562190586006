import { User } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { LI, P, UL } from '@/components/typography'

type IntroductionProps = {
  user: User
}
const Introduction = ({ user }: IntroductionProps) => {
  const { t } = useTranslation()
  return (
    <div>
      <P>{t(i18nKeys.beeoimpact.methodology.introduction.p1)}</P>
      <P>{t(i18nKeys.beeoimpact.methodology.introduction.p2)}</P>
      <span>{t(i18nKeys.beeoimpact.methodology.introduction.p3)}</span>
      <UL className="ml-16">
        {hasAccesTo(user.features, FeatureType.POTENTIAL) && (
          <LI>
            <span className="font-bold">{t(i18nKeys.beeoimpact.methodology.introduction.ul.li1.title)}: </span>
            {t(i18nKeys.beeoimpact.methodology.introduction.ul.li1.description)}
          </LI>
        )}
        {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
          <LI>
            <span className="font-bold">{t(i18nKeys.beeoimpact.methodology.introduction.ul.li2.title)}: </span>
            {t(i18nKeys.beeoimpact.methodology.introduction.ul.li2.description)}
          </LI>
        )}
        {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
          <LI>
            <span className="font-bold">{t(i18nKeys.beeoimpact.methodology.introduction.ul.li3.title)}: </span>
            {t(i18nKeys.beeoimpact.methodology.introduction.ul.li3.description)}
          </LI>
        )}
      </UL>
      <P>{t(i18nKeys.beeoimpact.methodology.introduction.p4)}</P>
      <UL className="ml-12">
        <LI className="list-none">
          <blockquote className="mt-6 border-l-2 pl-6 italic">
            {t(i18nKeys.beeoimpact.methodology.introduction.blockquote1)}
          </blockquote>
        </LI>
        <LI className="list-none">
          <blockquote className="mt-6 border-l-2 pl-6 italic">
            {t(i18nKeys.beeoimpact.methodology.introduction.blockquote2)}
          </blockquote>
        </LI>
      </UL>
    </div>
  )
}

export default Introduction
