import { Site } from '@/client/backend/models'
import { Separator } from '@radix-ui/react-select'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { DEFAULT_RADIUS, getSiteCenter } from '@/lib/geo'
import { hasAccesTo } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import { useScreenDetector } from '@/hooks/useScreenDetector'
import { Card, CardContent, CardTitle } from '@/components/ui/card'
import BadgeWithIcon from '@/components/badge-with-icon'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import { H4 } from '@/components/typography'

type SiteCardProps = {
  site: Site
  onSiteHoverEnter: (id: number) => void
  onSiteHoverOut: (id: number) => void
  className?: string
  to: string
  withScoring?: boolean
}
const SiteCard = ({ site, onSiteHoverEnter, onSiteHoverOut, className, to, withScoring = true }: SiteCardProps) => {
  const { user } = useAuth()
  const { isTablet } = useScreenDetector()

  if (!user) return <Loader />
  const center = getSiteCenter(site)

  return (
    <Link to={to} className={className}>
      <Card
        className=" rounded-xl border-none shadow-none hover:cursor-pointer"
        onMouseEnter={() => onSiteHoverEnter(site.id)}
        onMouseOut={() => onSiteHoverOut(site.id)}
      >
        <div className="flex flex-col">
          <CardTitle>
            <MapContainer
              className="3xl:h-[400px] z-0 h-72 min-w-[180px] rounded-2xl hover:cursor-pointer sm:h-48  md:h-48 xl:h-[240px]"
              center={center}
              zoom={isTablet ? 5 : 12}
              dragging={true}
              scrollWheelZoom={false}
              zoomControl={false}
              attributionControl={false}
            >
              <Circle center={center} color="blue" fillColor="none" weight={1} radius={DEFAULT_RADIUS} />
              <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
          </CardTitle>
        </div>
        <CardContent className="border-none px-0">
          <div className="my-2 flex flex-row items-center justify-between gap-x-2">
            <H4 className="mt-0">{site.properties?.name}</H4>
          </div>

          {withScoring && (
            <div className="flex flex-row gap-0.5">
              {hasAccesTo(user.features, FeatureType.PESTICIDES) && (
                <BadgeWithIcon
                  scoring={site.properties?.stats_pesticides?.ecoscore}
                  icon={<Icons.FlaskConical className="mr-1 inline-block size-4 align-text-top" />}
                />
              )}
              <Separator dir="vertical" />
              {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
                <BadgeWithIcon
                  scoring={site.properties?.stats_biodiversity?.biodiversity_score}
                  icon={<Icons.Leaf className="mr-1 inline-block size-4 align-text-top" />}
                />
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </Link>
  )
}

export default SiteCard
