import { useMemo } from 'react'
import { LatLng, LatLngBounds } from 'leaflet'
import { LayersControl, MapContainer, Marker, TileLayer } from 'react-leaflet'

import { SpectralColorLegend } from '@/lib/constants/spectral-legend'
import { DEFAULT_RADIUS, getBoundsFromCenterAndRadius } from '@/lib/geo'
import { getMapIcon } from '@/lib/map'
import { cn } from '@/lib/utils'

import InteractiveColorLegendOverlay from './interactive-color-legend-overlay'

export type SpectralImageLayer = {
  url: string
  bounds: LatLngBounds
  opacity: number
  type: string
  legends: SpectralColorLegend[]
}
type MapWithSpectralOverlaysProps = {
  spectralLayers: SpectralImageLayer[]
  center: LatLng
  radius?: number
  maxBounds?: LatLngBounds
  legend?: string
  className?: string
}

const MapWithSpectralImages = ({
  center,
  radius,
  maxBounds,
  spectralLayers,
  legend,
  className,
}: MapWithSpectralOverlaysProps) => {
  const mapRadius = radius ?? DEFAULT_RADIUS
  const mapBounds = useMemo(() => {
    return getBoundsFromCenterAndRadius(center, mapRadius)
  }, [center, mapRadius])

  return (
    <div className={cn('!size-96 !max-h-96 bg-slate-700', className)}>
      <MapContainer className="z-0 size-full" center={center} maxBounds={maxBounds} bounds={mapBounds}>
        <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution={legend} />
        {spectralLayers.length > 0 && (
          <LayersControl position="topright" collapsed={false}>
            {spectralLayers.map(({ bounds, opacity, url, type, legends }, i) => (
              <LayersControl.BaseLayer key={type} name={type} checked={i === 0}>
                <InteractiveColorLegendOverlay url={url} bounds={bounds} opacity={opacity} legends={legends} />
              </LayersControl.BaseLayer>
            ))}
          </LayersControl>
        )}
        <Marker icon={getMapIcon('pin')} position={center}></Marker>
      </MapContainer>
    </div>
  )
}

export default MapWithSpectralImages
