import { SpectralIndex } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

import { SpectralChart } from './beeoimpact/impacts/spectral-chart'

type CardSpectralProps = {
  center: LatLng
  data?: SpectralIndex[]
}

const CardSpectral = ({ center, data }: CardSpectralProps) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.card.title)}</CardTitle>
      </CardHeader>
      <CardContent>
        {data && data.length > 0 ? (
          <SpectralChart center={center} data={data} />
        ) : (
          <p>{t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.noData)}</p>
        )}
      </CardContent>
    </Card>
  )
}

export default CardSpectral
