import { i18nKeys } from '@/locales/keys'

type Color = {
  r: number
  g: number
  b: number
}

export type SpectralColorLegend = {
  color: Color
  labelKey: string
}

const NDVI_COLORS = {
  BLACK_FOR_CLOUD: { r: 0, g: 0, b: 0 },
  BLACK: { r: 13, g: 13, b: 13 },
  LIGHT_GRAY: { r: 191, g: 191, b: 191 },
  WHITE: { r: 235, g: 235, b: 235 },
  PALE_YELLOW: { r: 255, g: 250, b: 204 },
  LIGHT_YELLOW: { r: 237, g: 232, b: 181 },
  BEIGE: { r: 222, g: 217, b: 156 },
  YELLOW_BROWN: { r: 204, g: 199, b: 130 },
  LIGHT_BROWN: { r: 189, g: 184, b: 107 },
  GREEN_YELLOW: { r: 176, g: 194, b: 97 },
  GREEN: { r: 145, g: 191, b: 82 },
  DARK_GREEN: { r: 97, g: 150, b: 54 },
  VERY_DARK_GREEN: { r: 0, g: 69, b: 0 },
} as const

const NDWI_COLORS = {
  BLACK_FOR_CLOUD: { r: 0, g: 0, b: 0 },
  DARK_GRAY: { r: 77, g: 77, b: 77 },
  GRAY: { r: 153, g: 153, b: 153 },
  LIGHT_GRAY: { r: 230, g: 230, b: 230 },
  LIGHT_BLUE: { r: 128, g: 204, b: 230 },
  DARK_BLUE: { r: 26, g: 128, b: 204 },
} as const

export const ndviLegends: SpectralColorLegend[] = [
  {
    color: NDVI_COLORS.BLACK_FOR_CLOUD,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.cloud,
  },
  {
    color: NDVI_COLORS.BLACK,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.waterNoVegetation,
  },
  {
    color: NDVI_COLORS.LIGHT_GRAY,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.bareSoilUrbanAreas,
  },
  {
    color: NDVI_COLORS.WHITE,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.verySparseVegetation,
  },
  {
    color: NDVI_COLORS.PALE_YELLOW,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.lowVegetationCover,
  },
  {
    color: NDVI_COLORS.LIGHT_YELLOW,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.sparseGrassDrySoil,
  },
  {
    color: NDVI_COLORS.BEIGE,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.semiAridVegetation,
  },
  {
    color: NDVI_COLORS.YELLOW_BROWN,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.lowToModerateVegetation,
  },
  {
    color: NDVI_COLORS.LIGHT_BROWN,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.managedCropsGrasslands,
  },
  {
    color: NDVI_COLORS.GREEN_YELLOW,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.moderateVegetation,
  },
  {
    color: NDVI_COLORS.GREEN,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.healthyVegetation,
  },
  {
    color: NDVI_COLORS.DARK_GREEN,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.denseVegetation,
  },
  {
    color: NDVI_COLORS.VERY_DARK_GREEN,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndviLegends.veryDenseHealthyVegetation,
  },
]

export const ndwiLegends: SpectralColorLegend[] = [
  {
    color: NDWI_COLORS.BLACK_FOR_CLOUD,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.cloud,
  },
  {
    color: NDWI_COLORS.DARK_GRAY,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.dryAreasOrBareSoil,
  },
  {
    color: NDWI_COLORS.GRAY,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.minimalWaterPresence,
  },
  {
    color: NDWI_COLORS.LIGHT_GRAY,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.lowWaterContentInVegetation,
  },
  {
    color: NDWI_COLORS.LIGHT_BLUE,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.wetSoilOrModerateWaterBodies,
  },
  {
    color: NDWI_COLORS.DARK_BLUE,
    labelKey: i18nKeys.beeoimpact.biodiversityTable.spectralIndices.ndwiLegends.highWaterContentOrOpenWater,
  },
]
