import { i18nKeys } from '@/locales/keys'
import { MapPin } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const NoSitesFound = () => {
  const { t } = useTranslation()
  return (
    <div className="col-span-full flex min-h-[350px]  w-full items-center justify-center rounded-md border border-dashed">
      <div className="flex flex-col items-center justify-center text-center">
        <MapPin className="size-4" />
        <h3 className="mt-4 text-lg font-semibold">{t(i18nKeys.global.common.errors.noSitesFound.title)}</h3>
        <p className="mb-4 mt-2 text-sm text-muted-foreground">
          {t(i18nKeys.global.common.errors.noSitesFound.description)}
        </p>
      </div>
    </div>
  )
}

export default NoSitesFound
