import { useMemo, useState } from 'react'
import { SpectralIndex } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, LabelList, Legend, Line, LineChart, ReferenceLine, XAxis, YAxis } from 'recharts'

import { ndviLegends, ndwiLegends, SpectralColorLegend } from '@/lib/constants/spectral-legend'
import { getSeason } from '@/lib/date'
import { getBoundsFromGeometry } from '@/lib/geo'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import MapWithSpectralImages, { SpectralImageLayer } from '@/components/map/map-with-spectral-overlays'

type SpectralChartProps = {
  center?: LatLng
  data: SpectralIndex[]
}

type ChartDateItem = {
  startDate: string
  period: string
  NDWI: number
  NDVI: number
  overlays: SpectralImageLayer[]
}

const getFormattedSeason = (date: string, getTranslation: (key: string) => string) => {
  const d = new Date(date)
  const y = d.getFullYear().toString().slice(-2)
  const season = getTranslation(i18nKeys.global.common.season[getSeason(d)])

  return `${season} ${y}`
}

const getOverlay = (
  { index_type, image_url, bounding_box }: SpectralIndex,
  legends: SpectralColorLegend[]
): SpectralImageLayer | null =>
  image_url && bounding_box
    ? {
        type: index_type,
        url: image_url,
        bounds: getBoundsFromGeometry({ ...bounding_box, type: bounding_box.type || 'Polygon' }),
        opacity: 0.8,
        legends,
      }
    : null

const transformChartData = (data: SpectralIndex[]): ChartDateItem[] => {
  const allDates = [...new Set(data.map(({ start_date }) => start_date))].sort()

  const transformedData = allDates
    .map((date) => {
      const ndwiItem = data.find(({ start_date, index_type }) => start_date === date && index_type === 'NDWI')
      const ndviItem = data.find(({ start_date, index_type }) => start_date === date && index_type === 'NDVI')

      if (!ndwiItem || !ndviItem) return null

      const start = new Date(ndwiItem.start_date).toLocaleDateString()
      const end = new Date(ndwiItem.end_date).toLocaleDateString()

      return {
        startDate: ndwiItem.start_date,
        period: `${start} - ${end}`,
        NDWI: ndwiItem.mean_value,
        NDVI: ndviItem.mean_value,
        overlays: [getOverlay(ndviItem, ndviLegends), getOverlay(ndwiItem, ndwiLegends)].filter(
          (item): item is SpectralImageLayer => !!item
        ),
      }
    })
    .filter((item): item is ChartDateItem => !!item)

  return transformedData
}

const chartConfig = {
  NDWI: {
    label: 'NDWI',
    color: 'hsl(var(--biodiv-blueSixHundred))',
  },
  NDVI: {
    label: 'NDVI',
    color: 'hsl(var(--biodiv-tealSevenHundred))',
  },
} satisfies ChartConfig

export const SpectralChart = ({ center, data }: SpectralChartProps) => {
  const { t } = useTranslation()
  const chartData = useMemo(() => transformChartData(data), [data])
  const [selectedDate, setSelectedDate] = useState<string | undefined>(chartData.at(-1)?.startDate)

  const selectedMapData = useMemo(
    () => chartData.find(({ startDate }) => startDate === selectedDate),
    [chartData, selectedDate]
  )

  const hasOverlays = selectedMapData?.overlays && selectedMapData.overlays.length > 0

  const onSelectPoint = (point) => {
    if (point.activeLabel) {
      setSelectedDate(point.activeLabel)
    }
  }

  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <ChartContainer config={chartConfig} className="max-h-96 w-full cursor-pointer">
        <LineChart
          data={chartData}
          margin={{
            top: 20,
            left: -14,
            right: 20,
          }}
          style={{ cursor: 'pointer' }}
          onClick={onSelectPoint}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="startDate"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickFormatter={(d) => getFormattedSeason(d, t)}
          />
          <YAxis tickLine={false} axisLine={false} tickMargin={8} />
          <ChartTooltip
            cursor={{ strokeWidth: 2 }}
            content={<ChartTooltipContent labelFormatter={(d) => getFormattedSeason(d, t)} />}
          />
          {hasOverlays && <ReferenceLine x={selectedDate} strokeWidth={4} />}
          <Legend
            iconType="circle"
            height={30}
            wrapperStyle={{
              marginBottom: '-15px', // This adds some space below the legend
            }}
          />
          <Line
            dataKey="NDWI"
            type="monotone"
            stroke={chartConfig.NDWI.color}
            strokeWidth={2}
            dot={{
              fill: chartConfig.NDWI.color,
            }}
            activeDot={{
              r: 6,
            }}
            connectNulls
          >
            <LabelList
              dataKey="NDWI"
              position="top"
              offset={10}
              formatter={(value) => (value ? value.toFixed(2) : '')}
            />
          </Line>
          <Line
            dataKey="NDVI"
            type="monotone"
            stroke={chartConfig.NDVI.color}
            strokeWidth={2}
            dot={{
              fill: chartConfig.NDVI.color,
            }}
            activeDot={{
              r: 6,
            }}
            connectNulls
          >
            <LabelList
              dataKey="NDVI"
              position="top"
              offset={10}
              formatter={(value) => (value ? value.toFixed(2) : '')}
            />
          </Line>
        </LineChart>
      </ChartContainer>
      {hasOverlays && center && (
        <MapWithSpectralImages
          spectralLayers={selectedMapData.overlays}
          center={center}
          legend={selectedMapData.period}
          maxBounds={selectedMapData.overlays[0].bounds}
          className="!w-full lg:!size-96"
        />
      )}
    </div>
  )
}
