import { useMemo, useState } from 'react'
import { actionsTakenRetrieve, getActionsTakenRetrieveQueryKey } from '@/client/backend/api/actions-taken/actions-taken'
import {
  getInitiativesListQueryKey,
  getInitiativesRetrieveQueryKey,
  getInitiativesSitesListQueryKey,
  initiativesList,
  initiativesRetrieve,
  initiativesSitesList,
} from '@/client/backend/api/initiatives/initiatives'
import { getQuestionsListQueryKey, questionsList } from '@/client/backend/api/questions/questions'
import {
  getSitesResponsesListQueryKey,
  getSitesRetrieveQueryKey,
  sitesResponsesList,
  sitesRetrieve,
} from '@/client/backend/api/sites/sites'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import MonthlySchedule from '@/pages/beeoinitiative/date-planning'
import { FormCreateActionTaken } from '@/pages/beeoinitiative/form-create-action-taken'
import { FormModifyActionTaken } from '@/pages/beeoinitiative/form-modify-action-taken'
import InitCardsCarousel from '@/pages/beeoinitiative/site-detail/init-cards-carousel'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { dateUpdateFormat } from '@/lib/date-update-format'
import { DEFAULT_INITIATIVE_CATEGORY, filterInitiativeOnSiteResponses, INITIATIVE_CATEGORIES } from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import CategoryIcon from '@/components/beeoinitiative/category-icon'
import InitiativeDetailDescription from '@/components/beeoinitiative/initiative-detail-description'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { Icons } from '@/components/icons'
import Rating from '@/components/rating'

function impactText(value, t) {
  const impact = {
    1: t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.lowImpact1),
    2: t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.lowImpact2),
    3: t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.moderateImpact),
    4: t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.highImpact1),
    5: t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.highImpact2),
    NA: t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.na),
  }
  return impact[value] || impact.NA
}

function impactLevel(value, t) {
  const impact = {
    1: t(i18nKeys.beeoinitiative.initiativeDetailPage.low),
    2: t(i18nKeys.beeoinitiative.initiativeDetailPage.low),
    3: t(i18nKeys.beeoinitiative.initiativeDetailPage.moderate),
    4: t(i18nKeys.beeoinitiative.initiativeDetailPage.high),
    5: t(i18nKeys.beeoinitiative.initiativeDetailPage.high),
    NA: t(i18nKeys.beeoinitiative.initiativeDetailPage.na),
  }
  return impact[value] || impact.NA
}

function costText(value, t) {
  const cost = {
    1: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.lowCost1),
    2: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.lowCost2),
    3: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.moderateCost),
    4: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.highCost1),
    5: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.highCost2),
    NA: t(i18nKeys.beeoinitiative.initiativeDetailPage.cost.na),
  }
  return cost[value] || cost.NA
}

function costLevel(value, t) {
  const cost = {
    1: t(i18nKeys.beeoinitiative.initiativeDetailPage.veryLow),
    2: t(i18nKeys.beeoinitiative.initiativeDetailPage.low),
    3: t(i18nKeys.beeoinitiative.initiativeDetailPage.moderate),
    4: t(i18nKeys.beeoinitiative.initiativeDetailPage.high),
    5: t(i18nKeys.beeoinitiative.initiativeDetailPage.veryHigh),
    NA: t(i18nKeys.beeoinitiative.initiativeDetailPage.na),
  }
  return cost[value] || cost.NA
}

function getTimeLine(timeLine?: string): number[] {
  if (timeLine === 'All') {
    return Array.from({ length: 12 }, (_, i) => i + 1)
  }
  return timeLine ? timeLine.split(',').map(Number) : []
}

const InitiativeDetailsPage = () => {
  const { siteId, initiativeId, actionTakenId } = useParams<{
    siteId?: string
    initiativeId?: string
    actionTakenId?: string
  }>()
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false)

  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getSitesRetrieveQueryKey(siteId!)],
    queryFn: () => sitesRetrieve(siteId!),
    enabled: !!siteId,
  })

  const { data: initiative, isLoading: isLoadingInitiative } = useQuery({
    queryKey: [getInitiativesRetrieveQueryKey(initiativeId!)],
    queryFn: () => initiativesRetrieve(initiativeId!),
    enabled: !!initiativeId,
  })

  const { data: actionTaken } = useQuery({
    queryKey: [getActionsTakenRetrieveQueryKey(actionTakenId!)],
    queryFn: () => actionsTakenRetrieve(actionTakenId!),
    enabled: !!actionTakenId,
  })

  const { data: initiatives, isLoading: isLoadingInitiatives } = useQuery({
    queryKey: [getInitiativesListQueryKey()],
    queryFn: () => initiativesList(),
  })

  const { data: responses, isLoading: isLoadingResponses } = useQuery({
    queryKey: [getSitesResponsesListQueryKey(siteId!)],
    queryFn: () => sitesResponsesList(siteId!),
    enabled: !!siteId,
  })

  const { data: questions } = useQuery({
    queryKey: [getQuestionsListQueryKey()],
    queryFn: () => questionsList(),
  })

  const siteInitiativeList = useQuery({
    queryKey: [getInitiativesSitesListQueryKey(initiativeId!)],
    queryFn: () => initiativesSitesList(initiativeId!),
    enabled: !!initiativeId,
  })

  const { t } = useTranslation()
  const initiativeCategory = initiative?.category
    ? INITIATIVE_CATEGORIES[initiative?.category]
    : DEFAULT_INITIATIVE_CATEGORY

  const filteredInitsBasedOnResponses = initiatives?.filter((initiative) =>
    filterInitiativeOnSiteResponses(initiative, responses)
  )

  const initiativesCount = useMemo(() => {
    if (initiatives && responses) {
      return initiatives?.filter((init) => filterInitiativeOnSiteResponses(init, responses)).length
    } else {
      return 0
    }
  }, [initiatives, responses])

  return (
    <div className="mx-11">
      <BreadcrumbSection t={t} fullName={initiative?.full_name ?? ''} site={site} />
      {isLoadingInitiative ? (
        <Skeleton className="h-[230px] w-full" />
      ) : (
        <Card className="flex w-full flex-col" style={{ borderColor: initiativeCategory.color + '95' }}>
          <CardHeader>
            <CardTitle className="pb-6 text-3xl font-semibold leading-5 text-black"> {initiative?.full_name}</CardTitle>
            <div className="flex items-center gap-5 pb-3">
              <div className="flex items-center">
                <div
                  className="mr-2 flex size-[26px] items-center justify-center rounded-xl border"
                  style={{ borderColor: initiativeCategory.color, backgroundColor: initiativeCategory.color + '10' }}
                >
                  {initiativeCategory.iconName && (
                    <CategoryIcon
                      name={initiativeCategory.iconName}
                      color={initiativeCategory.color}
                      className="size-4"
                    />
                  )}
                </div>
                <div className="text-xs font-medium" style={{ color: initiativeCategory.color }}>
                  {t(initiativeCategory.labelKey)}
                </div>
              </div>
              {actionTaken && (
                <Badge className="flex h-[25px] w-[72px] justify-center bg-biodivTealSevenHundred">
                  {t(i18nKeys.beeoinitiative.initiativeDetailPage.planned)}
                </Badge>
              )}
              {site && (
                <div className="flex space-x-1 font-semibold">
                  <Icons.MapPin />
                  <div>{site.properties?.name}</div>
                </div>
              )}
              {actionTaken && (
                <div className="flex space-x-4">
                  <p className="flex gap-x-1 font-semibold">
                    <Icons.Calendar />
                    {dateUpdateFormat(actionTaken?.date_done ?? actionTaken?.date_start ?? null)}
                  </p>
                  <p className="flex gap-x-1 font-semibold">
                    <Icons.Euro className="text-biodivTealSevenHundred" />
                    {actionTaken.planned_cost}
                  </p>
                  <p
                    className="cursor-pointer underline"
                    onClick={() => {
                      setIsModifyDialogOpen(true)
                    }}
                  >
                    {t(i18nKeys.beeoinitiative.initiativeDetailPage.modify)}
                  </p>
                </div>
              )}

              {actionTaken && initiative && site && (
                <div>
                  <FormModifyActionTaken
                    initiative={initiative}
                    currentSiteId={site.id}
                    open={isModifyDialogOpen}
                    setOpen={setIsModifyDialogOpen}
                    actionTaken={actionTaken}
                  />
                </div>
              )}

              <div className="ml-auto min-w-52">
                {initiative && site && !actionTaken && (
                  <FormCreateActionTaken initiative={initiative} currentSiteId={site?.id} buttonWidth="w-[203px]" />
                )}
              </div>
            </div>
            <Separator />
          </CardHeader>

          <CardContent>
            <p>{initiative?.definition}</p>
          </CardContent>
        </Card>
      )}
      <div className="mb-20 mt-4 flex flex-wrap gap-6 lg:flex-nowrap">
        {isLoadingInitiative ? (
          <Skeleton className="w-full lg:max-w-96" />
        ) : (
          <Card className="w-full lg:max-w-96">
            <img
              src={initiative?.image_url ? initiative?.image_url : initiative?.image1}
              alt="image"
              className="block w-full rounded-lg"
            />
            <CardContent>
              <ImpactAndCostSection impact={initiative?.impact} cost={initiative?.cost} t={t} />
              <Separator />
              <TimelineSection timeline={getTimeLine(initiative?.time_line || 'null')} t={t} />
              <Separator />
              <PlanningSection initiative={initiative} site={site} t={t} />
              <Separator />
              <ConcernedSitesSection siteInitiativeList={siteInitiativeList} t={t} />
            </CardContent>
          </Card>
        )}
        <div className="space-y-6">
          <InitiativeDetailDescription
            title={t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.impactsOnBiodiversity)}
            bgColor="bg-biodivStoneHundred"
            description={initiative?.benefit}
            borderColor="border-emerald-500"
            icon={<Icons.Leaf className="size-8" />}
            iconColor="text-biodivLimeFiveHundred"
            isLoading={isLoadingInitiative}
            size="h-[184px] w-[1300px]"
          />

          <InitiativeDetailDescription
            title={t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.attentionPoints)}
            bgColor="bg-white"
            description={initiative?.recommendation}
            icon={<Icons.TriangleAlert className="size-8" />}
            iconColor="text-biodivRedWarning"
            isLoading={isLoadingInitiative}
            size="h-[50px] w-[1086]"
          />

          <InitiativeDetailDescription
            implementationTitle={t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.implement)}
            bgColor="bg-white"
            isLoading={isLoadingInitiative}
            description={initiative?.how}
          />
          {isLoadingInitiative ? <Skeleton className="h-16 w-[1086]" /> : <ForMoreInformation t={t} />}
        </div>
      </div>
      <div>
        {isLoadingSite || isLoadingInitiatives || isLoadingResponses ? (
          <div className="flex flex-row space-x-4">
            {[...Array(4)].map((_, index) => (
              <Skeleton key={index} className="h-[400px] w-[365px]" />
            ))}
          </div>
        ) : (
          site && (
            <InitCardsCarousel
              site={site}
              filteredInitsBasedOnResponses={filteredInitsBasedOnResponses}
              questions={questions}
              responses={responses}
              initiativesCount={initiativesCount}
              isLoading={isLoadingResponses}
            />
          )
        )}
      </div>
    </div>
  )
}

const BreadcrumbSection = ({ t, fullName, site }: { t; fullName: string; site?: Site | null }) => {
  const baseBreadcrumb = [
    {
      label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
      href: '/',
    },
    {
      label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
    },
  ]

  const getSiteBreadcrumb = (site: Site) => [
    {
      label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
      href: getPath(Routes.BEEOINITIATIVE_SITES),
    },
    {
      label: site.properties?.name,
      href: getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } }),
    },
  ]

  const getInitiativeBreadcrumb = () => [
    {
      label: t(i18nKeys.beeoinitiative.common.breadcrumb.initiatives),
      href: getPath(Routes.BEEOINITIATIVE_INITIATIVES),
    },
  ]

  const itemsBreadcrumb = [...baseBreadcrumb, ...(site ? getSiteBreadcrumb(site) : getInitiativeBreadcrumb())]

  return <BreadcrumbComponent breadcrumbItems={itemsBreadcrumb} currentPage={fullName} />
}

const ImpactAndCostSection = ({ impact, cost, t }) => (
  <div>
    {impact && (
      <>
        <div className="mb-4 mt-7">
          <div className="mb-3 flex items-center justify-between">
            <p className="text-lg font-semibold">{t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.impact)}</p>
            <Rating
              rating={impact ?? -1}
              filledColor="text-biodivTealSevenHundred"
              unfilledColor="text-biodivLightGrey"
              icon={<Icons.Leaf className="size-5" />}
            />
          </div>
          <p className="text-biodivTealSevenHundred">{impactLevel(impact, t)}</p>
          <div>{impactText(impact, t)}</div>
        </div>
        <Separator />
      </>
    )}
    <div className="mb-4 mt-7">
      <div className="mb-3 flex items-center justify-between">
        <p className="text-lg font-semibold">{t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.cost)}</p>
        <Rating
          rating={cost ?? -1}
          filledColor="text-biodivTealSevenHundred"
          unfilledColor="text-biodivLightGrey"
          icon={<Icons.Euro className="size-5" />}
        />
      </div>
      <p className="text-biodivTealSevenHundred">{costLevel(cost, t)}</p>
      <div>{costText(cost, t)}</div>
    </div>
  </div>
)

const TimelineSection = ({ timeline, t }) => (
  <div className="mb-9 mt-7">
    <p className="mb-4 text-lg font-semibold">
      {t(i18nKeys.beeoinitiative.initiativeDetailPage.impact.installationPeriod)}
    </p>
    <MonthlySchedule monthsToColor={timeline} />
  </div>
)

const PlanningSection = ({ initiative, site, t }) => (
  <div className="mb-9 mt-7">
    <Icons.CalendarPlus className="mb-4 size-8 text-biodivLimeFiveHundred" />
    <p className="mb-3 text-lg font-semibold">{t(i18nKeys.beeoinitiative.initiativeDetailPage.planThisInitiative)}</p>
    <div>{t(i18nKeys.beeoinitiative.initiativeDetailPage.addThisInitiativeToSite)}</div>
    <div className="mt-4">
      <FormCreateActionTaken initiative={initiative} currentSiteId={site?.id} buttonWidth="w-full" />
    </div>
  </div>
)

const ConcernedSitesSection = ({ siteInitiativeList, t }) => (
  <div className="mb-4 mt-7">
    <Icons.Earth className="mb-4 size-8 text-biodivLimeFiveHundred" />
    <p className="mb-3 text-lg font-semibold">{t(i18nKeys.beeoinitiative.initiativeDetailPage.concernedSites)}</p>
    <div className="lowercase">
      {t(i18nKeys.beeoinitiative.initiativeDetailPage.thisInitiativeHadBeenTakenBy)} {siteInitiativeList.data?.length}{' '}
      {siteInitiativeList.data?.length ?? 0 > 1
        ? t(i18nKeys.beeoinitiative.initiativeDetailPage.sites)
        : t(i18nKeys.beeoinitiative.initiativeDetailPage.site)}{' '}
      :
    </div>
    <ul className="ml-2 mt-4 list-inside list-disc">
      {siteInitiativeList.data?.slice(0, 5).map((site) => <li key={site.id}>{site.name}</li>) || []}
    </ul>
  </div>
)

const ForMoreInformation = ({ t }) => (
  <Card className="border-biodivLimeFiveHundred">
    <div className="flex h-full items-center justify-center py-5">
      <div>
        {t(i18nKeys.beeoinitiative.initiativeDetailPage.forMoreInformation)}:
        <a href="mailto:info@beeodiversity.com" className="pl-1 text-sm underline">
          {t(i18nKeys.beeoinitiative.initiativeDetailPage.contactUs)}
        </a>
      </div>
    </div>
  </Card>
)

export default InitiativeDetailsPage
