import { Routes } from '@/lib/routes/routes'
import { RouteOptions } from '@/lib/routes/types'

import { routeConfig } from './routes'

const getPathForRoute = (routeKey: Routes, options?: RouteOptions): string => {
  const route = routeConfig[routeKey]
  const { params = {} } = options ?? {} // Cast params to non-optional type with default value

  let path = route.path

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      path = path.replace(`:${key}`, encodeURIComponent(value))
    }
  }

  if (route.parent) {
    const parentOptions = { ...options, params: { ...params, [route.parent]: params[route.parent] } }
    const parentPath = getPathForRoute(route.parent, parentOptions)
    path = `${parentPath}${path}`
  }

  return path
}

export const getPath = (routeKey: Routes, options?: RouteOptions): string => {
  const path = getPathForRoute(routeKey, options)
  const pathWithQueryParams = options?.queryParams ? addQueryParams(path, options.queryParams) : path
  return options?.section ? `${pathWithQueryParams}#${options.section}` : pathWithQueryParams
}

const addQueryParams = (pathname: string, queryParams: { [key: string]: string | boolean | number } = {}) =>
  pathname +
  Object.entries(queryParams).reduce((acc, [key, value], index) => {
    if (value === null) return acc
    const prefix = index === 0 ? '?' : '&'
    return acc + `${prefix}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  }, '')
