import { useState } from 'react'
import { useActionsTakenUpdate } from '@/client/backend/api/actions-taken/actions-taken'
import { getSitesActionsTakenListQueryKey } from '@/client/backend/api/sites/sites'
import { ActionTaken } from '@/client/backend/models/actionTaken'
import { Initiative } from '@/client/backend/models/initiative'
import { Site } from '@/client/backend/models/site'
import { i18nKeys } from '@/locales/keys'
import { CostInputField } from '@/pages/beeoinitiative/components/initiative-form-fields'
import InitiativeCard from '@/pages/beeoinitiative/initiatives/initiative-card'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'

interface FormClosePlannedActionProps {
  initiative: Initiative
  actionTaken: ActionTaken
  site: Site
  isLoading: boolean
}
const FormClosePlannedAction = ({ initiative, site, actionTaken, isLoading }: FormClosePlannedActionProps) => {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)

  const FormSchema = z.object({
    cost: z.coerce.number().int().positive(),
  })
  const { t } = useTranslation()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const mutation = useActionsTakenUpdate()

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    mutation.mutate(
      {
        id: actionTaken.id.toString(),
        data: { spent_budget: data.cost.toString(), status: 'DONE' },
      },
      {
        onSuccess: () => {
          setOpen(false)
        },
        onError: () => {},
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [getSitesActionsTakenListQueryKey(site.id.toString()), { include: 'initiative' }],
          })
        },
      }
    )
  }

  const CostInputFieldReal = ({ form }) => {
    return (
      <FormField
        control={form.control}
        name="cost"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold">{t(i18nKeys.beeoinitiative.site.closeSheet.realCost)}</FormLabel>
            <FormControl>
              <Input placeholder="€" {...field} autoFocus />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    )
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" className={`border-black font-semibold`}>
          {t(i18nKeys.beeoinitiative.site.close)}
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[470px]">
        <SheetHeader>
          <SheetTitle className="mb-4 flex items-end gap-3">
            <span className="text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.closeSheet.title)}</span>
          </SheetTitle>
          <Separator />
        </SheetHeader>

        <div className="mt-9 max-h-[calc(100vh-160px)] overflow-y-auto px-3">
          <p className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectedInitiative)}
          </p>
          <div className="mb-4 mt-3 max-w-[357px]">
            <InitiativeCard
              initiative={initiative}
              site={site}
              buttons={false}
              cardHeight="h-[350px]"
              isLoading={isLoading}
            />
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <CostInputField form={form} showDisabledInput={true} value={actionTaken.planned_cost ?? ''} />
              <CostInputFieldReal form={form} />
              <div className="pt-14">
                <Separator />
              </div>
              <SheetFooter>
                <Button
                  variant="outline"
                  type="submit"
                  disabled={mutation.isPending}
                  className="w-full border-black font-semibold"
                >
                  {mutation.isPending && <Loader2 className="mr-2 size-4 animate-spin" />}
                  {t(i18nKeys.beeoinitiative.site.closeSheet.button)}
                </Button>
              </SheetFooter>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default FormClosePlannedAction
