import {
  accountsRetrieve,
  accountsSitesList,
  accountsUsersList,
  getAccountsRetrieveQueryKey,
  getAccountsSitesListQueryKey,
  getAccountsUsersListQueryKey,
} from '@/client/backend/api/accounts/accounts'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SettingsSitesTable from '@/components/settings-sites-table'
import UsersTable from '@/components/users-table'

const AccountDetailPage = () => {
  const { t } = useTranslation()
  const { accountId } = useParams<{ accountId: string }>()

  const parsedAccountId = accountId ? parseInt(accountId, 10) : null

  const { data: account } = useQuery({
    queryKey: parsedAccountId ? [getAccountsRetrieveQueryKey(parsedAccountId)] : [],
    queryFn: () => accountsRetrieve(parsedAccountId!),
    enabled: Boolean(parsedAccountId),
  })

  const { data: sites, isLoading: isLoadingDataSites } = useQuery({
    queryKey: parsedAccountId ? [getAccountsSitesListQueryKey(parsedAccountId)] : [],
    queryFn: () => accountsSitesList(parsedAccountId!),
    enabled: Boolean(parsedAccountId),
  })

  const { data: users, isLoading: isLoadingDataUsers } = useQuery({
    queryKey: parsedAccountId ? [getAccountsUsersListQueryKey(parsedAccountId)] : [],
    queryFn: () => accountsUsersList(parsedAccountId!),
    enabled: Boolean(parsedAccountId),
  })

  return (
    <Tabs defaultValue="sites">
      <TabsList>
        <TabsTrigger value="sites">Sites</TabsTrigger>
        <TabsTrigger value="users">Users</TabsTrigger>
      </TabsList>

      <TabsContent value="sites">
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium">Sites</h3>
            <p className="text-sm text-muted-foreground">You will find the {account?.name} sites.</p>
          </div>
          <Separator />
          {isLoadingDataSites ? (
            <Skeleton className="block h-[300px]" />
          ) : (
            <SettingsSitesTable data={sites?.features ?? []} accountId={account?.id} />
          )}
        </div>
      </TabsContent>

      <TabsContent value="users">
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.users.title)}</h3>
            <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.users.description)}</p>
          </div>
          <Separator />
          {isLoadingDataUsers ? (
            <Skeleton className="block h-[300px]" />
          ) : (
            <UsersTable
              users={users ?? []}
              accountId={account?.id}
              queryKey={getAccountsUsersListQueryKey(parsedAccountId!)}
            />
          )}
        </div>
      </TabsContent>
    </Tabs>
  )
}

export default AccountDetailPage
