import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { Crosshair, FlaskConical, Leaf } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ScoringType } from '@/types/scoring-type'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'

interface SitesCardImpactProps {
  site: Site
  onSiteHover: () => void
  onSiteHoverOut: () => void
  link: string
  scoringType: ScoringType
}

const SiteCardImpact = ({ site, onSiteHover, onSiteHoverOut, link, scoringType }: SitesCardImpactProps) => {
  const { t } = useTranslation()

  const scoring = {
    [ScoringType.BIODIVERSITY]: site?.properties?.stats_biodiversity?.biodiversity_score,
    [ScoringType.PESTICIDES]: site?.properties?.stats_pesticides?.ecoscore,
  }

  const scoreBiodivTextColor = (score) => {
    if (score === undefined) {
      return 'text-gray-600'
    }
    if (score >= 71 && score <= 100) {
      return 'text-[#4CAF50]'
    }
    if (score > 50 && score < 71) {
      return 'text-[#FF9800]'
    }
    if (score > 20 && score <= 50) {
      return 'text-[#FF9800]'
    }
    if (score >= 0 && score <= 20) {
      return 'text-[#F44336]'
    }
    return 'text-gray-600'
  }

  const scorePesticidesTextColor = (score) => {
    if (score === undefined) {
      return 'text-gray-600'
    }
    if (score >= 71 && score <= 100) {
      return 'text-[#4CAF50]'
    }
    if (score > 50 && score < 71) {
      return 'text-[#FF9800]'
    }
    if (score > 20 && score <= 50) {
      return 'text-[#FF9800]'
    }
    if (score >= 0 && score <= 20) {
      return 'text-[#F44336]'
    }
    return 'text-gray-600'
  }

  return (
    <Card
      className="flex min-h-[280px] w-full max-w-[360px] flex-col px-8"
      onMouseOver={onSiteHover}
      onMouseOut={onSiteHoverOut}
    >
      <div className="pb-1 pt-4">
        <p className="truncate text-xl font-semibold">{site?.properties?.name}</p>
        <p className="text-sm text-muted-foreground">
          {t(i18nKeys.global.settings.page.sites.buildingType[site?.properties?.building_type ?? ''])} -{' '}
          {site?.properties?.biodiversity_radius}m
        </p>
      </div>
      <Separator className="bg-biodivLimeFiveHundred" />

      <div className="space-y-4">
        <Separator />
        <div className="flex items-center">
          <div className="flex flex-1 flex-col items-center text-sm">
            <div className="flex items-center space-x-1 ">
              {scoringType === ScoringType.BIODIVERSITY ? (
                <Leaf className={cn('size-3.5 font-semibold', scoreBiodivTextColor(scoring[scoringType]))} />
              ) : (
                <FlaskConical
                  className={cn('size-3.5 font-semibold', scorePesticidesTextColor(scoring[scoringType]))}
                />
              )}

              <p className="font-bold">{scoring[scoringType]}%</p>
            </div>

            <p className="p-2 text-center text-xs">{t(i18nKeys.beeoimpact.sites.labels[scoringType])}</p>
          </div>
          <Separator orientation="vertical" />
          {scoringType === ScoringType.BIODIVERSITY && (
            <div className="flex flex-1 flex-col items-center text-sm">
              <div className="flex items-center space-x-1">
                <Crosshair className={'size-3.5 font-semibold text-red-600'} />
                <p className="font-bold">{site?.properties?.stats_biodiversity?.nb_total_threatened_species}</p>
              </div>
              <p className="p-2 text-center text-xs">Esp. menacées</p>
            </div>
          )}
        </div>
        <Separator />
      </div>
      <div className="flex items-center justify-center pt-6">
        <Link to={link}>
          <Button variant="outline" size="lg" className="h-[35px] w-24 border-2 border-black">
            {t(i18nKeys.beeoinitiative.sites.details)}
          </Button>
        </Link>
      </div>
    </Card>
  )
}

export default SiteCardImpact
