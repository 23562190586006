import React, { useRef, useState } from 'react'
import { accountsCreate, accountsUpdate, getAccountsListQueryKey } from '@/client/backend/api/accounts/accounts'
import { getNaceCodesListQueryKey, naceCodesList } from '@/client/backend/api/nace-codes/nace-codes'
import { Account, FeaturesEnum } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Loader, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddAccountFormValues, addAccountSchema } from '@/lib/validation/add-account-schema'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { MultiSelect } from '@/components/multi-select'
import { SingleSelect } from '@/components/single-select'

type AccountFormProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  account?: Account
}

const AccountForm = ({ account, setOpen }: AccountFormProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const formRef = useRef<HTMLFormElement>(null)

  const [searchNaceCode, setSearchNaceCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // React Hook Form setup
  const form = useForm<AddAccountFormValues>({
    resolver: zodResolver(addAccountSchema),
    defaultValues: {
      name: account?.name || '',
      features: account?.features || [FeaturesEnum.biodiversity, FeaturesEnum.pesticides],
      nace_code: account?.nace_code || '',
    },
    shouldUnregister: false,
    mode: 'onSubmit',
  })

  // Create account mutation
  const createAccountMutation = useMutation({
    mutationFn: (newAccount: Account) => accountsCreate(newAccount),
    onMutate: () => setIsLoading(true),
    onSuccess: (createdAccount) => {
      queryClient.setQueryData<Account[]>([getAccountsListQueryKey()], (old) => {
        if (!old) return [createdAccount]
        return [...old, createdAccount]
      })

      toast({ title: t(i18nKeys.global.admin.page.accounts.toast.success) })
      setOpen(false)
      setIsLoading(false)
    },
    onError: () => {
      toast({
        title: t(i18nKeys.global.admin.page.accounts.toast.error),
        variant: 'destructive',
      })
      setOpen(true)
      setIsLoading(false)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [getAccountsListQueryKey()] })
    },
  })

  // Update account mutation
  const updateAccountMutation = useMutation({
    mutationFn: (updatedAccount: Account) => accountsUpdate(updatedAccount.id, updatedAccount),
    onMutate: () => setIsLoading(true),
    onSuccess: (serverUpdatedAccount) => {
      queryClient.setQueryData<Account[]>([getAccountsListQueryKey()], (old) => {
        if (!old) return [serverUpdatedAccount]
        return old.map((acc) => (acc.id === serverUpdatedAccount.id ? serverUpdatedAccount : acc))
      })

      toast({ title: t(i18nKeys.global.admin.page.accounts.toast.success) })
      setOpen(false)
      setIsLoading(false)
    },
    onError: () => {
      toast({
        title: t(i18nKeys.global.admin.page.accounts.toast.error),
        variant: 'destructive',
      })
      setOpen(true)
      setIsLoading(false)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [getAccountsListQueryKey()] })
    },
  })

  // Fetch NACE codes (only when search is not empty)
  const { data: naceCodes } = useQuery({
    queryKey: [getNaceCodesListQueryKey({ search: searchNaceCode })],
    queryFn: () => naceCodesList({ search: searchNaceCode }),
    enabled: !!searchNaceCode,
  })

  // Form submission handler
  const onSubmit = form.handleSubmit((data) => {
    setIsLoading(true)
    if (account) {
      const updatedAccount: Account = {
        ...account,
        name: data.name,
        features: data.features,
        ...(data.nace_code ? { nace_code: data.nace_code } : {}), // Only update if nace_code is not empty
      }
      updateAccountMutation.mutate(updatedAccount)
    } else {
      const newAccount: Account = {
        name: data.name,
        features: data.features,
        ...(data.nace_code ? { nace_code: data.nace_code } : {}), // Only update if nace_code is not empty
        nb_sites: '0', // Default value
        nb_users: '0', // Default value
        id: 0, // Empty number or generate UUID
      }
      createAccountMutation.mutate(newAccount)
    }
  })

  if (!user) return <Loader />

  return (
    <Form {...form}>
      <form ref={formRef} onSubmit={onSubmit} className="space-y-8">
        {/* Name Field */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.global.admin.page.accounts.table.header.name)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Features Field */}
        <FormField
          control={form.control}
          name="features"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.global.admin.page.accounts.table.header.Produits)}</FormLabel>
              <MultiSelect
                selected={field.value}
                options={Object.values(FeaturesEnum).map((feature) => ({
                  value: feature,
                  label: feature,
                }))}
                {...field}
                className="sm:w-[315px]"
              />
              <FormMessage />
            </FormItem>
          )}
        />

        {/* NACE Code Field */}
        <FormField
          control={form.control}
          name="nace_code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.global.settings.page.sites.label.nace_code)}</FormLabel>
              <SingleSelect
                selected={field.value ?? ''}
                searchInput={searchNaceCode}
                setSearchInput={setSearchNaceCode}
                options={
                  naceCodes?.map((code) => ({
                    value: code.code,
                    label: `${code.code} - ${code.name}`,
                  })) ?? []
                }
                {...field}
                className="h-56 sm:w-[315px]"
                emptyCommand={t(i18nKeys.global.settings.page.multiSelect.noNaceCode)}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Submit Button */}
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button type="submit" disabled={isLoading}>
            {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
            {account
              ? t(i18nKeys.global.admin.page.accounts.sheet.edit.title)
              : t(i18nKeys.global.admin.page.accounts.sheet.add.title)}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default AccountForm
