import * as React from 'react'
import { useState } from 'react'
import { i18nKeys } from '@/locales/keys'
import { Check, ChevronsUpDown, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

type OptionType = {
  label: string
  value: string
}

interface SingleSelectProps {
  options?: OptionType[]
  selected: string | null
  onChange: React.Dispatch<React.SetStateAction<string | null>>
  className?: string
  emptyCommand: string
  searchInput: string
  setSearchInput: (value: string) => void
}

const SingleSelect = React.forwardRef<HTMLDivElement, SingleSelectProps>(
  ({ options = [], selected, onChange, className, emptyCommand, searchInput, setSearchInput, ...props }, ref) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const handleSelect = (item: string) => {
      onChange(item)
    }

    const handleUnselect = () => {
      onChange(null)
    }

    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchInput.toLowerCase()))

    return (
      <Popover open={open} onOpenChange={setOpen} {...props} modal={true}>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
            onClick={() => setOpen(!open)}
          >
            {selected ? (
              <div className="flex items-center">
                <Badge variant="secondary" className="mb-1 mr-1">
                  {selected}
                  <div
                    className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onClick={handleUnselect}
                  >
                    <X className="size-3 text-muted-foreground hover:text-foreground" />
                  </div>
                </Badge>
              </div>
            ) : (
              <span>{t(i18nKeys.global.settings.page.multiSelect.selectItem)}</span>
            )}
            <ChevronsUpDown className="size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0" ref={ref}>
          <Command className={className}>
            <CommandInput
              placeholder="Search ..."
              value={searchInput}
              onValueChange={(value) => setSearchInput(value)}
            />
            <CommandList>
              <CommandEmpty>{emptyCommand}</CommandEmpty>
              <CommandGroup className="max-h-64 overflow-auto">
                {filteredOptions.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => {
                      handleSelect(option.value)
                      setOpen(true)
                    }}
                  >
                    <Check className={cn('mr-2 size-4', selected === option.value ? 'opacity-100' : 'opacity-0')} />
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    )
  }
)

export { SingleSelect }
