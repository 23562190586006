import { SortOption } from '@/types/sort-options'
import { SortType } from '@/types/sort-type'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

interface SitesFilterScoringProps {
  onSortChange: (sortType: SortType) => void
  placeholder: string
  sortConfig: Record<SortType, SortOption>
}

const SitesFilterScoring = ({ onSortChange, placeholder, sortConfig }: SitesFilterScoringProps) => {
  return (
    <Select onValueChange={onSortChange}>
      <SelectTrigger className="w-max space-x-2 text-muted-foreground">
        <div className="flex items-center space-x-1">
          <SelectValue placeholder={placeholder} />
        </div>
      </SelectTrigger>
      <SelectContent>
        {Object.entries(sortConfig).map(([key, option]) => (
          <SelectItem key={key} value={key}>
            <div className="flex items-center space-x-2">
              {option.icon}
              {option.label}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default SitesFilterScoring
