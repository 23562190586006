import { i18nKeys } from '@/locales/keys'
import { Trans, useTranslation } from 'react-i18next'

import { H4, P } from '@/components/typography'

const Habitat = () => {
  const { t } = useTranslation()
  return (
    <div className="py-4">
      <H4>{t(i18nKeys.beeoimpact.methodology.habitats.title)}</H4>
      <P>
        <Trans
          i18nKey={i18nKeys.beeoimpact.methodology.habitats.description}
          components={{ bold: <span className="font-bold" /> }}
        />
      </P>
    </div>
  )
}

export default Habitat
