import { FeaturesEnum } from '@/client/backend/models'
import { z } from 'zod'

export const addAccountSchema = z.object({
  name: z.string().nonempty().max(150),
  features: z.array(z.nativeEnum(FeaturesEnum)).nonempty(),
  nace_code: z.string().optional(),
})

export type AddAccountFormValues = z.infer<typeof addAccountSchema>
