import { ExternalLinks } from '@/lib/constants/external-links'
import { cn } from '@/lib/utils'
import { Icons } from '@/components/icons'
import Image from '@/components/image'

const Footer = ({ className = '', ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const commitSha = import.meta.env.VITE_APP_COMMIT_SHA
  const branch = import.meta.env.VITE_APP_BRANCH

  const year = new Date().getFullYear()

  return (
    <footer
      className={cn('border-t px-8 py-6 md:px-8 md:py-0 print:w-full print:border-none print:py-0', className)}
      {...props}
    >
      <div className="flex flex-col items-center justify-between gap-4 md:h-24 md:flex-row print:flex-row">
        <div className="col-span-12">
          <p className="text-center text-sm leading-loose text-muted-foreground md:text-left print:text-left">
            Copyright © {year} BeeOdiversity
          </p>

          {branch && commitSha && (
            <p className="text-xs text-muted-foreground">
              <span className="pr-1">{branch} - </span>
              <a
                href={`https://github.com/beeodiversity/beeoimpact-web/commit/${commitSha}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {commitSha}
              </a>
            </p>
          )}
        </div>

        <div className="flex items-center justify-center text-sm text-muted-foreground">
          <a href="https://eic.ec.europa.eu/index_en">
            <Image
              src="https://beeodiversitystorage.blob.core.windows.net/public/EIC-CoFundedBy-quadri.png"
              alt="eic-logo"
            />
          </a>
        </div>

        <div className="flex justify-end">
          <a
            href={ExternalLinks.Beeodiversity}
            target="_blank"
            rel="noreferrer"
            className="mr-4 font-medium underline underline-offset-4"
          >
            <Icons.Globe className="size-5 text-muted-foreground" />
          </a>
          <a
            href={ExternalLinks.LinkedIn}
            target="_blank"
            rel="noreferrer"
            className="mr-4 font-medium underline underline-offset-4"
          >
            <Icons.Linkedin className="size-5 text-muted-foreground" />
          </a>

          <a
            href={ExternalLinks.Instagram}
            target="_blank"
            rel="noreferrer"
            className="mr-4 font-medium underline underline-offset-4"
          >
            <Icons.Instagram className="size-5 text-muted-foreground" />
          </a>
          <a
            href={ExternalLinks.Facebook}
            target="_blank"
            rel="noreferrer"
            className=" font-medium underline underline-offset-4"
          >
            <Icons.Facebook className="size-5 text-muted-foreground" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
