import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { H2, H3, LI, P, UL } from '@/components/typography'

const PesticideRisk = () => {
  const { t } = useTranslation()
  return (
    <div className="py-4">
      <H2>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.title)}</H2>
      <P>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.description)}</P>
      <H3>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.scope.title)}</H3>
      <P>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.scope.description)}</P>

      <H3>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.score.title)}</H3>
      <P>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.score.description)}</P>

      <H3>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.title)}</H3>

      <P> {t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.description)}</P>

      <UL>
        <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs1)}</LI>
        <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs2)}</LI>
        <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs3)}</LI>
        <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs4)}</LI>
      </UL>
    </div>
  )
}

export default PesticideRisk
