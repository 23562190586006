import { i18nKeys } from '@/locales/keys'
import { Loader } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import BiodiversityRisk from '@/components/beeoimpact/methodology/biodiversity-risk'
import GeneralAnalysis from '@/components/beeoimpact/methodology/general-analysis'
import Introduction from '@/components/beeoimpact/methodology/introduction'
import PesticideRisk from '@/components/beeoimpact/methodology/pesticide-risk'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

const MethodologyPage = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  if (!user) return <Loader />

  return (
    <div className="container">
      <div className="flex-1 space-y-4 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>{t(i18nKeys.beeoimpact.methodology.title)}</PageHeaderHeading>
          </PageHeader>
        </div>
        <Introduction user={user} />
        {hasAccesTo(user.features, FeatureType.POTENTIAL) && <GeneralAnalysis />}
        {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
          <>
            <BiodiversityRisk />
          </>
        )}
        {hasAccesTo(user.features, FeatureType.PESTICIDES) && <PesticideRisk />}
      </div>
    </div>
  )
}

export default MethodologyPage
