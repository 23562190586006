import { useEffect, useRef, useState } from 'react'
import { usersCreate } from '@/client/backend/api/users/users'
import { User } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Loader, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AddUserFormValues, addUsersSchema } from '@/lib/validation/add-users-schema'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'
import { Icons } from '@/components/icons'

type UsersTableActionProps = {
  accountId?: number
  queryKey?: readonly string[]
}

const UsersTableAction = ({ accountId, queryKey }: UsersTableActionProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const createUserMutation = useMutation({
    mutationFn: (newUser: User) => usersCreate(newUser),
    onSuccess: () => {
      toast({
        title: t(i18nKeys.global.settings.page.users.toast.success),
      })
      setOpen(false)
    },
    onError: () => {
      toast({
        title: t(i18nKeys.global.settings.page.users.toast.error),
        variant: 'destructive',
      })
      setOpen(true)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] })
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (open) {
      form.reset()
    }
  }, [open])

  const form = useForm<AddUserFormValues>({
    resolver: zodResolver(addUsersSchema),
    defaultValues: {
      email: '',
      firstname: '',
      lastname: '',
    },
    shouldUnregister: false,
    mode: 'onSubmit',
  })
  if (!user) return <Loader />

  const onSubmit = (data) => {
    setIsLoading(true)

    const newAccount: User = {
      email: data.email,
      first_name: data.firstname,
      last_name: data.lastname,
      account_id: accountId ?? user.account_id,
      account: { id: 0, name: '' }, // default value
      features: [], // default value
      groups: [], // default value
      id: 0, // default value
      last_login: '', // default value
      nb_sites: 0, // default value
      username: '', // default value
    }

    createUserMutation.mutate(newAccount)
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="sm" className="ml-auto h-8 lg:flex">
          <Icons.UserPlus2 className="mr-2 size-4" />
          {t(i18nKeys.global.common.buttons.addUser)}
        </Button>
      </SheetTrigger>
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle> {t(i18nKeys.global.settings.page.users.sheet.title)}</SheetTitle>
          <SheetDescription>{t(i18nKeys.global.settings.page.users.sheet.description)}</SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(i18nKeys.global.settings.user.firstName)}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(i18nKeys.global.settings.user.lastName)}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel> {t(i18nKeys.global.settings.user.email)}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <SheetFooter>
              <Button type="submit" disabled={isLoading}>
                {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
                {t(i18nKeys.global.common.buttons.addUser)}
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}

export default UsersTableAction
