import { ReactElement } from 'react'
import { i18nKeys } from '@/locales/keys'
import { Table } from '@tanstack/react-table'
import { X } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  action?: ReactElement
  filterIdColumn?: string
}

export function DataTableToolbar<TData>({ table, action, filterIdColumn }: DataTableToolbarProps<TData>) {
  const { t } = useTranslation()

  if (!filterIdColumn) {
    return action && <div className="ml-2">{action}</div>
  }
  const isFiltered = table.getColumn(filterIdColumn)?.getFilterValue() !== undefined

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        {filterIdColumn && (
          <Input
            placeholder={t(i18nKeys.global.common.datatable.filter)}
            value={(table.getColumn(filterIdColumn)?.getFilterValue() as string) ?? ''}
            onChange={(event) => table.getColumn(filterIdColumn)?.setFilterValue(event.target.value)}
            className="h-8 w-[150px] lg:w-[250px]"
          />
        )}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => {
              table.getColumn(filterIdColumn)?.setFilterValue('')
              table.resetColumnFilters()
            }}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X className="ml-2 size-4" />
          </Button>
        )}
      </div>
      {action && <div className="ml-2">{action}</div>}
    </div>
  )
}
