import { Dispatch, SetStateAction } from 'react'
import { Site } from '@/client/backend/models/site'

import { isAdmin } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import AddBomSite from '@/components/add-bom-site'
import AddSite from '@/components/add-site'
import ImportSite from '@/components/import-site'

type SettingsSitesTableActionProps = {
  sites: Site[]
  setSites: Dispatch<SetStateAction<Site[]>>
  accountId?: number
}

const SettingsSitesTableAction = ({ sites, setSites, accountId }: SettingsSitesTableActionProps) => {
  const { user } = useAuth()

  return (
    <div className="flex flex-row">
      {isAdmin(user) && (
        <>
          <AddBomSite sites={sites} setSites={setSites} accountId={accountId} />
          <ImportSite accountId={accountId} />
          <AddSite sites={sites} setSites={setSites} accountId={accountId} />
        </>
      )}
    </div>
  )
}

export default SettingsSitesTableAction
