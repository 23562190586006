import { format as formatDate } from 'date-fns'
import { enUS, fr, nlBE } from 'date-fns/locale'

export const getDateFormat = (date: Date | number, language: string, format: string = 'PPP') => {
  let locale
  switch (language) {
    case 'fr':
      locale = fr
      break
    case 'nl':
      locale = nlBE
      break
    case 'en':
      locale = enUS
      break
  }

  return formatDate(date, format, { locale })
}

export const getSeason = (date: Date) => {
  const m = date.getMonth()
  const d = date.getDate()

  // Define season start dates
  const SPRING_START = { month: 2, day: 21 } // March 21
  const SUMMER_START = { month: 5, day: 21 } // June 21
  const FALL_START = { month: 8, day: 21 } // September 21
  const WINTER_START = { month: 11, day: 21 } // December 21

  // Check season based on month and day
  if (
    (m === WINTER_START.month && d >= WINTER_START.day) ||
    m === 0 || // January
    m === 1 || // February
    (m === SPRING_START.month && d < SPRING_START.day)
  ) {
    return 'winter'
  }

  if (
    (m === SPRING_START.month && d >= SPRING_START.day) ||
    m === 3 || // April
    m === 4 || // May
    (m === SUMMER_START.month && d < SUMMER_START.day)
  ) {
    return 'spring'
  }

  if (
    (m === SUMMER_START.month && d >= SUMMER_START.day) ||
    m === 6 || // July
    m === 7 || // August
    (m === FALL_START.month && d < FALL_START.day)
  ) {
    return 'summer'
  }

  if (
    (m === FALL_START.month && d >= FALL_START.day) ||
    m === 9 || // October
    m === 10 || // November
    (m === WINTER_START.month && d < WINTER_START.day)
  ) {
    return 'fall'
  }

  // Default case (shouldn't be reached with valid inputs)
  return 'winter'
}
