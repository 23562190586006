import { Skeleton } from '@/components/ui/skeleton'

const HeatmapSkeleton = () => {
  return (
    <div className="h-[400px]">
      <Skeleton className="size-full" />
    </div>
  )
}

export default HeatmapSkeleton
