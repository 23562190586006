import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Input } from '@/components/ui/input'

const SitesFilterSearch = ({ onInputChange }) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const searchValue = searchParams.get('search') || ''

  return (
    <Input
      id="search"
      className="w-[150px] lg:w-[250px]"
      placeholder={t(i18nKeys.global.common.datatable.filter)}
      onChange={onInputChange}
      value={searchValue}
    />
  )
}

export default SitesFilterSearch
