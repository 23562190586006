import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type {
  AccountAction,
  Activation,
  Impersonate,
  LightUser,
  PasswordResetConfirm,
  SendEmailReset,
  SetPassword,
  SetUsername,
  SiteList,
  User,
  UsernameResetConfirm,
  UsersResendActivationCreate400,
  UsersResetPasswordConfirmCreate400,
  UsersResetPasswordCreate400,
  UsersSetPasswordCreate400,
} from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const usersList = (signal?: AbortSignal) => {
  return backendInstance<User[]>({ url: `/users/`, method: 'GET', signal })
}

export const getUsersListQueryKey = () => {
  return [`/users/`] as const
}

export const getUsersListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersList>>> = ({ signal }) => usersList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersListQueryResult = NonNullable<Awaited<ReturnType<typeof usersList>>>
export type UsersListQueryError = ErrorType<unknown>

export const useUsersList = <TData = Awaited<ReturnType<typeof usersList>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const usersCreate = (user: NonReadonly<User>) => {
  return backendInstance<User>({
    url: `/users/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: user,
  })
}

export const getUsersCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersCreate>>, TError, { data: NonReadonly<User> }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof usersCreate>>, TError, { data: NonReadonly<User> }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersCreate>>, { data: NonReadonly<User> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return usersCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersCreate>>>
export type UsersCreateMutationBody = NonReadonly<User>
export type UsersCreateMutationError = ErrorType<unknown>

export const useUsersCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof usersCreate>>, TError, { data: NonReadonly<User> }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof usersCreate>>, TError, { data: NonReadonly<User> }, TContext> => {
  const mutationOptions = getUsersCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const usersRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<User>({ url: `/users/${id}/`, method: 'GET', signal })
}

export const getUsersRetrieveQueryKey = (id: number) => {
  return [`/users/${id}/`] as const
}

export const getUsersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersRetrieve>>> = ({ signal }) => usersRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersRetrieve>>>
export type UsersRetrieveQueryError = ErrorType<unknown>

export const useUsersRetrieve = <TData = Awaited<ReturnType<typeof usersRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve a URL for account actions (e.g., activation, password reset).
 */
export const usersActionUrlRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<AccountAction>({ url: `/users/${id}/action-url/`, method: 'GET', signal })
}

export const getUsersActionUrlRetrieveQueryKey = (id: number) => {
  return [`/users/${id}/action-url/`] as const
}

export const getUsersActionUrlRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersActionUrlRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersActionUrlRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersActionUrlRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersActionUrlRetrieve>>> = ({ signal }) =>
    usersActionUrlRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersActionUrlRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersActionUrlRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersActionUrlRetrieve>>>
export type UsersActionUrlRetrieveQueryError = ErrorType<unknown>

export const useUsersActionUrlRetrieve = <
  TData = Awaited<ReturnType<typeof usersActionUrlRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersActionUrlRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersActionUrlRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get managed users
 */
export const usersManagedUsersList = (id: number, signal?: AbortSignal) => {
  return backendInstance<LightUser[]>({ url: `/users/${id}/managed_users/`, method: 'GET', signal })
}

export const getUsersManagedUsersListQueryKey = (id: number) => {
  return [`/users/${id}/managed_users/`] as const
}

export const getUsersManagedUsersListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersManagedUsersList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersManagedUsersList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersManagedUsersListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersManagedUsersList>>> = ({ signal }) =>
    usersManagedUsersList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersManagedUsersList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersManagedUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof usersManagedUsersList>>>
export type UsersManagedUsersListQueryError = ErrorType<unknown>

export const useUsersManagedUsersList = <
  TData = Awaited<ReturnType<typeof usersManagedUsersList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersManagedUsersList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersManagedUsersListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get sites for user
 */
export const usersSitesList = (id: number, signal?: AbortSignal) => {
  return backendInstance<SiteList>({ url: `/users/${id}/sites/`, method: 'GET', signal })
}

export const getUsersSitesListQueryKey = (id: number) => {
  return [`/users/${id}/sites/`] as const
}

export const getUsersSitesListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersSitesList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersSitesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersSitesListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersSitesList>>> = ({ signal }) => usersSitesList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersSitesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersSitesListQueryResult = NonNullable<Awaited<ReturnType<typeof usersSitesList>>>
export type UsersSitesListQueryError = ErrorType<unknown>

export const useUsersSitesList = <TData = Awaited<ReturnType<typeof usersSitesList>>, TError = ErrorType<unknown>>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersSitesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersSitesListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get the token of an other user
 */
export const usersTokenRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<Impersonate>({ url: `/users/${id}/token/`, method: 'GET', signal })
}

export const getUsersTokenRetrieveQueryKey = (id: number) => {
  return [`/users/${id}/token/`] as const
}

export const getUsersTokenRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTokenRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTokenRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersTokenRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTokenRetrieve>>> = ({ signal }) =>
    usersTokenRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTokenRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersTokenRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersTokenRetrieve>>>
export type UsersTokenRetrieveQueryError = ErrorType<unknown>

export const useUsersTokenRetrieve = <
  TData = Awaited<ReturnType<typeof usersTokenRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTokenRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersTokenRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const usersActivationCreate = (activation: Activation) => {
  return backendInstance<Activation>({
    url: `/users/activation/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: activation,
  })
}

export const getUsersActivationCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersActivationCreate>>,
    TError,
    { data: Activation },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof usersActivationCreate>>, TError, { data: Activation }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersActivationCreate>>, { data: Activation }> = (
    props
  ) => {
    const { data } = props ?? {}

    return usersActivationCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersActivationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersActivationCreate>>>
export type UsersActivationCreateMutationBody = Activation
export type UsersActivationCreateMutationError = ErrorType<unknown>

export const useUsersActivationCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersActivationCreate>>,
    TError,
    { data: Activation },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof usersActivationCreate>>, TError, { data: Activation }, TContext> => {
  const mutationOptions = getUsersActivationCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const usersMeRetrieve = (signal?: AbortSignal) => {
  return backendInstance<User>({ url: `/users/me/`, method: 'GET', signal })
}

export const getUsersMeRetrieveQueryKey = () => {
  return [`/users/me/`] as const
}

export const getUsersMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMeRetrieve>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersMeRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeRetrieve>>> = ({ signal }) => usersMeRetrieve(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersMeRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type UsersMeRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeRetrieve>>>
export type UsersMeRetrieveQueryError = ErrorType<unknown>

export const useUsersMeRetrieve = <
  TData = Awaited<ReturnType<typeof usersMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersMeRetrieve>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getUsersMeRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * resend_activation
 */
export const usersResendActivationCreate = (sendEmailReset: SendEmailReset) => {
  return backendInstance<void>({
    url: `/users/resend_activation/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendEmailReset,
  })
}

export const getUsersResendActivationCreateMutationOptions = <
  TError = ErrorType<UsersResendActivationCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResendActivationCreate>>,
    TError,
    { data: SendEmailReset },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersResendActivationCreate>>,
  TError,
  { data: SendEmailReset },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersResendActivationCreate>>,
    { data: SendEmailReset }
  > = (props) => {
    const { data } = props ?? {}

    return usersResendActivationCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersResendActivationCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersResendActivationCreate>>
>
export type UsersResendActivationCreateMutationBody = SendEmailReset
export type UsersResendActivationCreateMutationError = ErrorType<UsersResendActivationCreate400>

export const useUsersResendActivationCreate = <
  TError = ErrorType<UsersResendActivationCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResendActivationCreate>>,
    TError,
    { data: SendEmailReset },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof usersResendActivationCreate>>,
  TError,
  { data: SendEmailReset },
  TContext
> => {
  const mutationOptions = getUsersResendActivationCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const usersResetEmailCreate = (sendEmailReset: SendEmailReset) => {
  return backendInstance<SendEmailReset>({
    url: `/users/reset_email/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendEmailReset,
  })
}

export const getUsersResetEmailCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetEmailCreate>>,
    TError,
    { data: SendEmailReset },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersResetEmailCreate>>,
  TError,
  { data: SendEmailReset },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersResetEmailCreate>>, { data: SendEmailReset }> = (
    props
  ) => {
    const { data } = props ?? {}

    return usersResetEmailCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersResetEmailCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersResetEmailCreate>>>
export type UsersResetEmailCreateMutationBody = SendEmailReset
export type UsersResetEmailCreateMutationError = ErrorType<unknown>

export const useUsersResetEmailCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetEmailCreate>>,
    TError,
    { data: SendEmailReset },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof usersResetEmailCreate>>,
  TError,
  { data: SendEmailReset },
  TContext
> => {
  const mutationOptions = getUsersResetEmailCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const usersResetEmailConfirmCreate = (usernameResetConfirm: UsernameResetConfirm) => {
  return backendInstance<UsernameResetConfirm>({
    url: `/users/reset_email_confirm/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: usernameResetConfirm,
  })
}

export const getUsersResetEmailConfirmCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetEmailConfirmCreate>>,
    TError,
    { data: UsernameResetConfirm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersResetEmailConfirmCreate>>,
  TError,
  { data: UsernameResetConfirm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersResetEmailConfirmCreate>>,
    { data: UsernameResetConfirm }
  > = (props) => {
    const { data } = props ?? {}

    return usersResetEmailConfirmCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersResetEmailConfirmCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersResetEmailConfirmCreate>>
>
export type UsersResetEmailConfirmCreateMutationBody = UsernameResetConfirm
export type UsersResetEmailConfirmCreateMutationError = ErrorType<unknown>

export const useUsersResetEmailConfirmCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetEmailConfirmCreate>>,
    TError,
    { data: UsernameResetConfirm },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof usersResetEmailConfirmCreate>>,
  TError,
  { data: UsernameResetConfirm },
  TContext
> => {
  const mutationOptions = getUsersResetEmailConfirmCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * reset_password
 */
export const usersResetPasswordCreate = (sendEmailReset: SendEmailReset) => {
  return backendInstance<void>({
    url: `/users/reset_password/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: sendEmailReset,
  })
}

export const getUsersResetPasswordCreateMutationOptions = <
  TError = ErrorType<UsersResetPasswordCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetPasswordCreate>>,
    TError,
    { data: SendEmailReset },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersResetPasswordCreate>>,
  TError,
  { data: SendEmailReset },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersResetPasswordCreate>>, { data: SendEmailReset }> = (
    props
  ) => {
    const { data } = props ?? {}

    return usersResetPasswordCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersResetPasswordCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersResetPasswordCreate>>>
export type UsersResetPasswordCreateMutationBody = SendEmailReset
export type UsersResetPasswordCreateMutationError = ErrorType<UsersResetPasswordCreate400>

export const useUsersResetPasswordCreate = <
  TError = ErrorType<UsersResetPasswordCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetPasswordCreate>>,
    TError,
    { data: SendEmailReset },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof usersResetPasswordCreate>>,
  TError,
  { data: SendEmailReset },
  TContext
> => {
  const mutationOptions = getUsersResetPasswordCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * reset_password_confirm
 */
export const usersResetPasswordConfirmCreate = (passwordResetConfirm: PasswordResetConfirm) => {
  return backendInstance<void>({
    url: `/users/reset_password_confirm/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: passwordResetConfirm,
  })
}

export const getUsersResetPasswordConfirmCreateMutationOptions = <
  TError = ErrorType<UsersResetPasswordConfirmCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetPasswordConfirmCreate>>,
    TError,
    { data: PasswordResetConfirm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersResetPasswordConfirmCreate>>,
  TError,
  { data: PasswordResetConfirm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersResetPasswordConfirmCreate>>,
    { data: PasswordResetConfirm }
  > = (props) => {
    const { data } = props ?? {}

    return usersResetPasswordConfirmCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersResetPasswordConfirmCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersResetPasswordConfirmCreate>>
>
export type UsersResetPasswordConfirmCreateMutationBody = PasswordResetConfirm
export type UsersResetPasswordConfirmCreateMutationError = ErrorType<UsersResetPasswordConfirmCreate400>

export const useUsersResetPasswordConfirmCreate = <
  TError = ErrorType<UsersResetPasswordConfirmCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersResetPasswordConfirmCreate>>,
    TError,
    { data: PasswordResetConfirm },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof usersResetPasswordConfirmCreate>>,
  TError,
  { data: PasswordResetConfirm },
  TContext
> => {
  const mutationOptions = getUsersResetPasswordConfirmCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const usersSetEmailCreate = (setUsername: SetUsername) => {
  return backendInstance<SetUsername>({
    url: `/users/set_email/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: setUsername,
  })
}

export const getUsersSetEmailCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersSetEmailCreate>>,
    TError,
    { data: SetUsername },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof usersSetEmailCreate>>, TError, { data: SetUsername }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSetEmailCreate>>, { data: SetUsername }> = (
    props
  ) => {
    const { data } = props ?? {}

    return usersSetEmailCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersSetEmailCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSetEmailCreate>>>
export type UsersSetEmailCreateMutationBody = SetUsername
export type UsersSetEmailCreateMutationError = ErrorType<unknown>

export const useUsersSetEmailCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersSetEmailCreate>>,
    TError,
    { data: SetUsername },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof usersSetEmailCreate>>, TError, { data: SetUsername }, TContext> => {
  const mutationOptions = getUsersSetEmailCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * set_password
 */
export const usersSetPasswordCreate = (setPassword: SetPassword) => {
  return backendInstance<void>({
    url: `/users/set_password/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: setPassword,
  })
}

export const getUsersSetPasswordCreateMutationOptions = <
  TError = ErrorType<UsersSetPasswordCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersSetPasswordCreate>>,
    TError,
    { data: SetPassword },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof usersSetPasswordCreate>>, TError, { data: SetPassword }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSetPasswordCreate>>, { data: SetPassword }> = (
    props
  ) => {
    const { data } = props ?? {}

    return usersSetPasswordCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UsersSetPasswordCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSetPasswordCreate>>>
export type UsersSetPasswordCreateMutationBody = SetPassword
export type UsersSetPasswordCreateMutationError = ErrorType<UsersSetPasswordCreate400>

export const useUsersSetPasswordCreate = <TError = ErrorType<UsersSetPasswordCreate400>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersSetPasswordCreate>>,
    TError,
    { data: SetPassword },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof usersSetPasswordCreate>>, TError, { data: SetPassword }, TContext> => {
  const mutationOptions = getUsersSetPasswordCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
