import { useState } from 'react'
import {
  getUsersActionUrlRetrieveQueryKey,
  usersActionUrlRetrieve,
  usersResendActivationCreate,
} from '@/client/backend/api/users/users'
import { LightUser, User } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { formatDistance } from 'date-fns'
import * as Locales from 'date-fns/locale'
import { Copy, Key, Loader2, LogIn } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo, isAdmin } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { useLanguage } from '@/hooks/useLanguage'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast'
import { Icons } from '@/components/icons'

/* -------------------------------------------------------------------------- */
/*                                 Interfaces                                 */
/* -------------------------------------------------------------------------- */

interface UsersTableRowActionsProps {
  user: User | LightUser
}

interface ImpersonateButtonProps {
  user: User | LightUser
}

interface ResetPasswordLinkDialogProps {
  user: User | LightUser
}

interface ActionsDropdownProps {
  user: User | LightUser
}

/* -------------------------------------------------------------------------- */
/*                           Sub-Components / Logic                           */
/* -------------------------------------------------------------------------- */

/**
 * Handles impersonating a user. Upon success, navigates based on user features.
 */
const ImpersonateButton = ({ user }: ImpersonateButtonProps) => {
  const [isImpersonating, setIsImpersonating] = useState(false)
  const navigate = useNavigate()
  const { impersonate } = useAuth()

  const handleImpersonate = async () => {
    setIsImpersonating(true)
    try {
      const impersonateUser = await impersonate(user.id)
      if (impersonateUser) {
        const path = hasAccesTo(impersonateUser.features, FeatureType.INITIATIVE)
          ? getPath(Routes.BEEOINITIATIVE_DASHBOARD)
          : getPath(Routes.BEEOIMPACT_DASHBOARD)
        navigate(path, { replace: true })
      }
    } finally {
      setIsImpersonating(false)
    }
  }

  return (
    <Button size="sm" variant="ghost" className="h-8 w-32" onClick={handleImpersonate} disabled={isImpersonating}>
      {isImpersonating ? (
        <>
          <Loader2 className="mr-2 size-4 animate-spin" />
          Loading...
        </>
      ) : (
        <>
          <LogIn className="mr-2 size-4" />
          Impersonate
        </>
      )}
    </Button>
  )
}

/**
 * Displays a dialog containing a reset-password link, which can be copied.
 */
const ResetPasswordLinkDialog = ({ user }: ResetPasswordLinkDialogProps) => {
  const [copied, setCopied] = useState(false)
  const { data: url } = useQuery({
    queryKey: [getUsersActionUrlRetrieveQueryKey(user.id)],
    queryFn: () => usersActionUrlRetrieve(user.id),
  })

  const handleCopy = async () => {
    if (url?.url) {
      await navigator.clipboard.writeText(url.url)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" size="sm">
          <Key className="mr-2 size-4" />
          Reset Password
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[525px]">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-2">
            <Key className="size-5" />
            Password Reset Link for {user.email}
          </AlertDialogTitle>
          <AlertDialogDescription className="space-y-4">
            {url ? (
              <div className="mt-4 space-y-4">
                <div className="flex items-center gap-3 rounded-md border bg-muted/50 p-3">
                  <code className="flex-1 break-all text-sm">{url.url}</code>
                  <Button size="sm" variant="ghost" onClick={handleCopy} className="w-36 shrink-0">
                    <Copy className="mr-2 size-4" />
                    {copied ? 'Copied!' : 'Copy'}
                  </Button>
                </div>
                <p className="text-sm text-muted-foreground">
                  This is a single-use link that will expire after being used.
                </p>
                <p className="text-sm text-muted-foreground">
                  The user will be asked to set a new password after clicking the link.
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-center py-8">Loading...</div>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>Close</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

/**
 * Dropdown menu with user-related actions:
 * - Impersonate (if admin)
 * - Reset Password
 */
const ActionsDropdown = ({ user }: ActionsDropdownProps) => {
  const { user: currentUser } = useAuth()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">Actions</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-44">
        {isAdmin(currentUser) && (
          <DropdownMenuItem asChild>
            <ImpersonateButton user={user} />
          </DropdownMenuItem>
        )}
        {isAdmin(currentUser) && (
          <DropdownMenuItem asChild>
            <ResetPasswordLinkDialog user={user} />
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

/* -------------------------------------------------------------------------- */
/*                            Main Row Actions Component                      */
/* -------------------------------------------------------------------------- */

/**
 * Displays actions for a single row in the Users table:
 * - Last login time or "Re-invite" button
 * - Actions dropdown (Impersonate, Reset Password)
 */
const UsersTableRowActions = ({ user }: UsersTableRowActionsProps) => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const { user: currentUser } = useAuth()

  const lastLoginDate = user.last_login ? new Date(user.last_login) : new Date()
  const formattedDistance = formatDistance(lastLoginDate, Date.now(), {
    addSuffix: true,
    locale: Locales[language],
  })

  const handleReInviteUser = async (email: string | undefined) => {
    if (!email) return
    try {
      await usersResendActivationCreate({ email })
      toast({
        title: t(i18nKeys.global.settings.page.users.toast.success),
      })
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.settings.page.users.toast.error),
          description: error.response.data[0],
        })
      }
    }
  }

  return (
    <div className="flex items-center justify-between gap-3">
      <div className="w-24">
        {!user.last_login ? (
          <Button size="sm" variant="outline" className="h-8" onClick={() => handleReInviteUser(user.email)}>
            <Icons.Pen className="mr-2 size-4" />
            {t(i18nKeys.global.common.buttons.reInviteUser)}
          </Button>
        ) : (
          <p>{formattedDistance}</p>
        )}
      </div>

      {isAdmin(currentUser) && <ActionsDropdown user={user} />}
    </div>
  )
}

export default UsersTableRowActions
