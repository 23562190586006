import { useState } from 'react'
import { accountsDestroy, getAccountsListQueryKey } from '@/client/backend/api/accounts/accounts'
import { Account } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { Pen, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'
import AccountForm from '@/components/account-form'

type AccountsTableRowActionProps = {
  account: Account
}

export default function AccountsTableRowAction({ account }: AccountsTableRowActionProps) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [isSheetOpen, setSheetOpen] = useState(false)
  const [isAlertOpen, setAlertOpen] = useState(false)

  const { mutate: deleteAccount } = useMutation({
    mutationFn: (accountId: number) => accountsDestroy(accountId),

    onMutate: async (deletedId) => {
      await queryClient.cancelQueries({ queryKey: [getAccountsListQueryKey()] })
      const previousAccounts = queryClient.getQueryData<Account[]>([getAccountsListQueryKey()])

      // Optimistically update the cache
      queryClient.setQueryData<Account[]>([getAccountsListQueryKey()], (oldAccounts = []) =>
        oldAccounts.filter((acct) => acct.id !== deletedId)
      )

      return { previousAccounts }
    },

    onError: (error, _vars, context) => {
      // Rollback the cache if mutation fails
      if (context?.previousAccounts) {
        queryClient.setQueryData([getAccountsListQueryKey()], context.previousAccounts)
      }

      // Display an error toast if the error is from Axios
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.admin.page.accounts.dialog.toast.error),
          description: error.response.data[0],
        })
      }
    },

    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries({ queryKey: [getAccountsListQueryKey()] })
    },

    onSuccess: () => {
      // Display a success toast
      toast({
        title: t(i18nKeys.global.admin.page.accounts.dialog.toast.success),
      })
      setAlertOpen(false)
    },
  })

  const handleDelete = () => {
    if (!account.id) return
    deleteAccount(account.id)
  }

  return (
    <div className="flex items-center justify-center">
      {/* Edit (Sheet) */}
      <Sheet open={isSheetOpen} onOpenChange={setSheetOpen}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Pen className="size-3" />
          </Button>
        </SheetTrigger>
        <SheetContent side="right" className="overflow-y-scroll">
          <SheetHeader>
            <SheetTitle>{t(i18nKeys.global.admin.page.accounts.sheet.edit.title)}</SheetTitle>
            <SheetDescription>{t(i18nKeys.global.admin.page.accounts.sheet.edit.description)}</SheetDescription>
          </SheetHeader>

          <AccountForm account={account} setOpen={setSheetOpen} />
        </SheetContent>
      </Sheet>

      {/* Delete (AlertDialog) */}
      <AlertDialog open={isAlertOpen} onOpenChange={setAlertOpen}>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Trash2 className="size-3" />
          </Button>
        </AlertDialogTrigger>

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t(i18nKeys.global.admin.page.accounts.dialog.title, { name: account.name })}
            </AlertDialogTitle>
            <AlertDialogDescription>{t(i18nKeys.global.admin.page.accounts.dialog.description)}</AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel>{t(i18nKeys.global.admin.page.accounts.dialog.button.cancel)}</AlertDialogCancel>

            <Button variant="destructive" onClick={handleDelete}>
              {t(i18nKeys.global.admin.page.accounts.dialog.button.delete)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
