import {
  getActionsTakenRetrieveQueryKey,
  useActionsTakenUpdate,
} from '@/client/backend/api/actions-taken/actions-taken'
import { getSitesActionsTakenListQueryKey } from '@/client/backend/api/sites/sites'
import { ActionTaken } from '@/client/backend/models'
import { Initiative } from '@/client/backend/models/initiative'
import { i18nKeys } from '@/locales/keys'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { InitiativeForm } from './components/initiative-form'

export const FormModifyActionTaken = ({
  initiative,
  currentSiteId,
  open,
  setOpen,
  actionTaken,
}: {
  initiative: Initiative
  currentSiteId: number
  open: boolean
  setOpen: (value: boolean) => void
  actionTaken: ActionTaken
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const mutation = useActionsTakenUpdate()
  const handleSubmit = (data) => {
    mutation.mutate(
      {
        id: actionTaken?.id.toString() ?? '',
        data: {
          date_start: data.date.toISOString(),
          status: 'PENDING',
          planned_cost: data.cost.toString(),
        },
      },
      {
        onSuccess: () => setOpen(false),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: [getActionsTakenRetrieveQueryKey(actionTaken.id.toString())] })
          queryClient.refetchQueries({
            queryKey: [getSitesActionsTakenListQueryKey(currentSiteId.toString()), { include: 'initiative' }],
          })
        },
      }
    )
  }
  return (
    <InitiativeForm
      initiative={initiative}
      currentSiteId={currentSiteId}
      open={open}
      setOpen={setOpen}
      onSubmit={handleSubmit}
      defaultValues={{
        date: actionTaken?.date_start ? new Date(actionTaken.date_start) : new Date(),
        site: actionTaken?.site ?? currentSiteId,
        cost: actionTaken?.planned_cost ? parseInt(actionTaken.planned_cost) : undefined,
      }}
      title={t(i18nKeys.beeoinitiative.initiativeDetailPage.modifyInitiative)}
      submitButtonText={t(i18nKeys.beeoinitiative.initiativeDetailPage.modify)}
      isSubmitting={mutation.isPending}
    />
  )
}
