import { useState } from 'react'
import { getSitesActionsTakenListQueryKey, useSitesActionsTakenCreate } from '@/client/backend/api/sites/sites'
import { Initiative } from '@/client/backend/models/initiative'
import { i18nKeys } from '@/locales/keys'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Sheet, SheetTrigger } from '@/components/ui/sheet'

import { InitiativeForm } from './components/initiative-form'

export const FormCreateActionTaken = ({
  initiative,
  currentSiteId,
  buttonWidth,
}: {
  initiative: Initiative
  currentSiteId?: number
  buttonWidth: string
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const mutation = useSitesActionsTakenCreate()

  const handleSubmit = (data) => {
    mutation.mutate(
      {
        id: data.site.toString(),
        data: {
          date_start: data.date.toISOString(),
          initiative: initiative?.id,
          status: 'PENDING',
          planned_cost: data.cost.toString(),
        },
      },
      {
        onSuccess: () => setOpen(false),
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [getSitesActionsTakenListQueryKey(data.site.toString()), { include: 'initiative' }],
          })
          queryClient.refetchQueries({
            queryKey: [getSitesActionsTakenListQueryKey(data.site.toString()), { include: 'initiative' }],
          })
        },
      }
    )
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" className={`${buttonWidth} border-black font-semibold`}>
          {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.plan)}
        </Button>
      </SheetTrigger>
      <InitiativeForm
        initiative={initiative}
        currentSiteId={currentSiteId}
        open={open}
        setOpen={setOpen}
        onSubmit={handleSubmit}
        title={t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.planAnInitiative)}
        submitButtonText={t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.plan)}
        isSubmitting={mutation.isPending}
      />
    </Sheet>
  )
}
