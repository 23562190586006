import Balance from 'react-wrap-balancer'

import { cn } from '@/lib/utils'

const PageHeader = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <section className={cn('mb-6 mt-3.5 flex max-w-[980px] flex-col items-start gap-2', className)} {...props}>
      {children}
    </section>
  )
}

const PageHeaderHeading = ({ className, ...props }: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h1
      className={cn('text-[45px] font-semibold leading-tight tracking-tighter lg:leading-[1.1]', className)}
      {...props}
    />
  )
}

const PageHeaderDescription = ({ className, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => {
  return <Balance className={cn('max-w-[750px] text-lg text-muted-foreground sm:text-xl', className)} {...props} />
}

export { PageHeader, PageHeaderDescription, PageHeaderHeading }
