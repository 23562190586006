import { PaginatedSiteList } from '@/client/backend/models'

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../ui/pagination'

interface PaginationControlsProps {
  paginatedData: PaginatedSiteList
  transformPath: (page: number) => string
}

const calculatePaginationRange = (currentPage: number, totalPages: number, displayedPages = 5) => {
  let startPage: number, endPage: number

  if (totalPages <= displayedPages) {
    startPage = 2
    endPage = totalPages - 1
  } else {
    const pagesBeforeCurrentPage = Math.floor(displayedPages / 2)
    const pagesAfterCurrentPage = Math.ceil(displayedPages / 2) - 1
    startPage = currentPage - pagesBeforeCurrentPage
    endPage = currentPage + pagesAfterCurrentPage

    if (startPage < 2) {
      endPage = endPage + (1 - startPage)
      startPage = 2
    }
    if (endPage >= totalPages) {
      startPage = startPage - (endPage - totalPages)
      endPage = totalPages - 1
    }
  }

  return { startPage, endPage }
}

export const PaginationControls = ({ paginatedData, transformPath }: PaginationControlsProps) => {
  const { startPage, endPage } = calculatePaginationRange(paginatedData.current_page, paginatedData.last_page)

  return (
    <div className="my-5">
      <Pagination>
        <PaginationContent>
          {paginatedData.previous_page && (
            <PaginationItem>
              <PaginationPrevious to={transformPath(paginatedData.previous_page)} replace={true} />
            </PaginationItem>
          )}

          {(paginatedData?.last_page ?? 0) > 1 && (
            <PaginationItem>
              <PaginationLink to={transformPath(1)} isActive={1 === paginatedData.current_page} replace={true}>
                1
              </PaginationLink>
            </PaginationItem>
          )}

          {startPage > 2 && (
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          )}

          {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
            <PaginationItem key={page}>
              <PaginationLink to={transformPath(page)} isActive={page === paginatedData.current_page} replace={true}>
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}

          {endPage < paginatedData.last_page - 1 && (
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          )}

          {paginatedData.last_page > 1 && (
            <PaginationItem>
              <PaginationLink
                to={transformPath(paginatedData.last_page)}
                isActive={paginatedData.last_page === paginatedData.current_page}
                replace={true}
              >
                {paginatedData.last_page}
              </PaginationLink>
            </PaginationItem>
          )}

          {paginatedData.next_page && (
            <PaginationItem>
              <PaginationNext to={transformPath(paginatedData.next_page)} replace={true} />
            </PaginationItem>
          )}
        </PaginationContent>
      </Pagination>
    </div>
  )
}
