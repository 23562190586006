import { Children, FC, HTMLAttributes, ReactElement, ThHTMLAttributes } from 'react'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { cn } from '@/lib/utils'

export const Table: FC<HTMLAttributes<HTMLDivElement> & { header: ReactElement; columns: number }> = ({
  header,
  columns,
  children,
  className = '',
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div className={cn('my-1 box-content', className)} {...props}>
      <table className="w-full table-fixed border-collapse border border-gray-300 text-xs">
        <thead className="box-content border-collapse border-spacing-1.5 border-gray-300  ">
          <tr className="">{header}</tr>
        </thead>
        <tbody className="border-collapse">
          {Children.count(children) ? (
            children
          ) : (
            <TableRow>
              <TableCell colSpan={columns} className="p-3 text-center align-middle">
                <span>{t(i18nKeys.global.common.datatable.emptyResults)}</span>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </table>
    </div>
  )
}

export const TableHeaderCell: FC<ThHTMLAttributes<HTMLTableCellElement>> = ({ children, className = '', ...props }) => {
  return (
    <th
      className={cn('h-12 bg-muted px-4 text-left align-middle font-medium text-muted-foreground', className)}
      {...props}
    >
      {children}
    </th>
  )
}

export const TableRow: FC<HTMLAttributes<HTMLTableRowElement>> = ({ children, className = '', ...props }) => {
  return (
    <tr className={cn('border-b', className)} {...props}>
      {children}
    </tr>
  )
}

export const TableCell: FC<ThHTMLAttributes<HTMLTableCellElement>> = ({ children, className = '', ...props }) => {
  return (
    <td className={cn('p-3 align-middle font-medium', className)} {...props}>
      {children}
    </td>
  )
}
